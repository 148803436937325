import React from "react";
import './TitleHeader.scss'

interface TitleProp {
  title: string,
  comment: string
}

const TitleHeader = ({title, comment}: TitleProp) => {
  return (
    <div className="TitleHeader">
      <p className="TitleHeader-title">{title}</p>
      <p className="TitleHeader-comment">{comment}</p>
    </div>
  )
}

const NewTitleHeader = ({title, comment}: TitleProp) => {
  return (
    <div className="TitleHeader New-TitleHeader">
      <p className="TitleHeader-title New-TitleHeader-title">{title}</p>
      {comment !== "" && (
        <p className="TitleHeader-comment New-TitleHeader-comment">{comment}</p>
      )}
    </div>
  )
}

export { TitleHeader, NewTitleHeader };