import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import './PrevReportTable.scss'
import { getTestData } from "../../../../data/repository/AuthRepository";
import getReport from "../../../../data/repository/ReportRepository";
import { LoginInfo, NewLoginInfo } from "../../../../data/dto/LoginInfo";
import { isReportVaild } from "../../../../data/usecase/AuthUsecase";

interface TestItem {
    hash: string,
    stu_no: string,
    test_no: string,
    test_type: string
}

interface PrevReportProps {
    data: any,
    loginInfo: NewLoginInfo
}

const PrevReportTable = ({data, loginInfo}: PrevReportProps) => {

    // const dataArr: TestItem[] = Object.values(data);
    const dataArr: TestItem[] = (Object.values(data) as TestItem[]).filter(item => parseInt(item.test_no) >= 14);
    // console.log(data);
    // console.log(loginInfo);

    const [testOptions, setTestOptions] = useState<any>();

    useEffect(() => {
        const fetchOptions = async () => {
          try {
            var response = await getTestData();
            setTestOptions(response.data);
          } catch (error) {
            console.log(error);
          }
        };

        fetchOptions();

    }, []);

    const findTestName = (test_no: string) => {
        // console.log(test_no);
        for (const year of Object.keys(testOptions)) {
            const yeardata = testOptions[year];
            for (const quarter of Object.keys(yeardata)) {
                const quarterdata = yeardata[quarter];
                for (const subject of Object.keys(quarterdata)) {
                    if (quarterdata[subject] == test_no) {
                        let subj = "영어";
                        if (subject === "math") subj = "수학";
                        let test_name = `${year}년 ${quarter}분기 ` + subj;
                        return test_name;
                    }

                }
            }
        }
        return "";
    }
    console.log(testOptions);

    const navigate = useNavigate();

    const showNewReport = async (test_info: TestItem) => {
        console.log(test_info);
        const selectedTest = {
            name: findTestName(test_info.test_no),
            id: test_info.test_no
        };
        // console.log(selectedTest);

        const subj = loginInfo.test.subject === "english" ? "" : loginInfo.test.subject + "/";

        const response = await getReport(`hash${test_info.hash}`, selectedTest.id, subj);

        // console.log(response);
        const report = response.data;

        if (isReportVaild(report)) {
            if (+selectedTest.id >= 14 && loginInfo.test.subject === "english") {
                const info: NewLoginInfo = {
                    test: {
                    name: selectedTest.name,
                    id: selectedTest.id,
                    subject: loginInfo.test.subject,
                    type: report.score.test_type
                    },
                    studentName: loginInfo.studentName,
                    studentGrade: report.score.grade,
                    studentLevel: report.score.lv,
                    phonenumber: loginInfo.phonenumber,
                };
    
                let testType = "";
                if (+report.score.test_type >= 3) testType = "1";
                else testType = "2";
    
                navigate("/v2/" + loginInfo.test.subject + testType, {
                    state: { report: report, info: info },
                });
            } else if (+selectedTest.id >= 14 && loginInfo.test.subject === "math") {
                // } else if (subject === "math") {
                const info: NewLoginInfo = {
                    test: {
                    name: selectedTest.name,
                    id: selectedTest.id,
                    subject: loginInfo.test.subject,
                    type: report.score.test_type
                    },
                    studentName: loginInfo.studentName,
                    studentGrade: report.score.grade,
                    studentLevel: report.score.lv,
                    phonenumber: loginInfo.phonenumber,
                };
                // console.log(info);
    
    
                navigate("/v2/" + loginInfo.test.subject, {
                    state: { report: report, info: info },
                });
            }
        }

    }
    

    return (
        <div>
            <div className="PrevReportTable">
                {dataArr.map((item, index) => (
                    item && testOptions && (
                    <div key={index} className={`PrevReportTableCell PrevTestHover ${item.test_no == loginInfo.test.id ? 'SelectedTestCell' : ''}`}
                    onClick={() => showNewReport(item)}>{findTestName(item.test_no)}</div>
                    )
                ))}
                {(dataArr.length % 2 === 1) &&
                    (<div className="PrevReportTableCell"></div>)
                }
            </div>
        </div>
    )

}


export default PrevReportTable;


