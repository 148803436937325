import React, { useState } from "react"
import {v4 as uuid} from "uuid";

import "./RightWrongTable.scss"

import { IntegratedTestSectorTable } from "./TestSectorTable";
import { NewTitleHeader } from "../component/TitleHeader";

const RightWrongTable = (data: any) => {
    const dataWrapper = data.data;
    console.log(dataWrapper);
    console.log(dataWrapper.length);
    const lis = [];
    let listtemp = [];
    for (let i = 0; i < dataWrapper.length; i++) {
        if (listtemp.length === 0) {
            listtemp.push(<tr key={uuid()}>
                <th>번호</th>
                <th>정오</th>
                <th>배점</th>
                <th>내용영역</th>
                <th>정답률</th>
              </tr>
            );
        }
        let info2val = dataWrapper[i].row.info2;
        if (i >= 45) info2val = "문법";
        listtemp.push(<tr key={uuid()}>
            <td>{dataWrapper[i].row.question_no}</td>
            <td>{dataWrapper[i].row.errata}</td>
            <td>{dataWrapper[i].row.points}</td>
            <td>{info2val}</td>
            <td>{dataWrapper[i].row.correct}%</td>
          </tr>
        );
        if (i % 15 == 14 || i === 49 || i === 54) {
            lis.push(listtemp);
            listtemp = [];
        }
    }
    // if (listtemp.length > 0) {
    //     lis.push(listtemp);
    //     listtemp = [];
    // }


    // const [IsGrammar, SetIsGrammar] = useState(false);
    // if (dataWrapper.length > 45) {
    //     SetIsGrammar(true);
        
    // }

    return (
        <div>
            <NewTitleHeader title="" comment="<수능>"/>
            <div className="rwTableContainer">
               <table><tbody>{lis[0]}</tbody></table>
               <table><tbody>{lis[1]}</tbody></table>
               <table><tbody>{lis[2]}</tbody></table>
            </div>
            {lis.length > 3 && (
                <>
                    <NewTitleHeader title="" comment="<문법>"/>
                    <div className="GrwTableContainer">
                        <table><tbody>{lis[3]}</tbody></table>
                        <table><tbody>{lis[4]}</tbody></table>
                        <table><tbody></tbody></table>
                    </div>
                </>
            )}
            
        </div>
    );
}

interface IntegratedRWprop {
    data: any,
    grade: string,
    test_no: number
}

const IntegratedRightWrongTable = ({data, grade, test_no}: IntegratedRWprop) => {
    const dataWrapper = data;
    let lis = [];
    let listtemp = [];

    for (let i = 0; i < 6; i++) {
        listtemp.push(<tr key={uuid()}>
            <th>번호</th>
            <th>정오</th>
            <th>배점</th>
            <th>내용영역</th>
            <th>정답률</th>
            </tr>
        );
        lis.push(listtemp);
        listtemp = [];
    }

    // console.log(lis);
    // console.log(dataWrapper.length);
    
    for (let i = 0; i < dataWrapper.length; i++) {
        let listindex = 6;
        if (dataWrapper[i].row.info1 === "LC 일반") listindex = 0;
        else if (dataWrapper[i].row.info1 === "LC 추론") listindex = 1;
        else if (dataWrapper[i].row.info1 === "GR 일반") listindex = 2;
        else if (dataWrapper[i].row.info1 === "RC 일반") listindex = 3;
        else if (dataWrapper[i].row.info1 === "RC 수능") listindex = 4;
        else if (dataWrapper[i].row.info1 === "RC 추론") listindex = 5;

       if (listindex < 6) {
           lis[listindex].push(<tr key={uuid()}>
               <td>{dataWrapper[i].row.question_no}</td>
               <td>{dataWrapper[i].row.errata}</td>
               <td>{dataWrapper[i].row.points}</td>
               <td>{dataWrapper[i].row.info2}</td>
               <td>{dataWrapper[i].row.correct}%</td>
             </tr>
           );
       } else {
        console.log(dataWrapper[i].row.question_no);
       }
    }

    return (
        <div>
            <div>
                <div className="Table-labels First-labels">
                    <p>LC 일반</p>
                    <p>LC 추론</p>
                    <p>GR</p>
                </div>
               <div className="rwTableContainer">
                   <table><tbody>{lis[0]}</tbody></table>
                   <table><tbody>{lis[1]}</tbody></table>
                   <table><tbody>{lis[2]}</tbody></table>
               </div>
               <div className="Dotted-line"></div>
                <div className="Table-labels">
                    <p>RC 일반</p>
                    <p>RC 수능</p>
                    <p>RC 추론</p>
                </div>
               <div className="rwTableContainer">
                   <table className="Table-12rows"><tbody>{lis[3]}</tbody></table>
                   <table className="Table-12rows"><tbody>{lis[4]}</tbody></table>
                   <table><tbody>{lis[5]}</tbody></table>
               </div>
            </div>

            <div className={+grade > 3 ? "Display-None" : "IntegratedTestSectorTableContainer"}>
                <div className="IntegratedTestSectorTitle">시험 구분</div>
                <IntegratedTestSectorTable test_no={test_no}></IntegratedTestSectorTable>
            </div>

            
        </div>
    );
}

const MathRightWrongTable = (data: any) => {
    const dataWrapper = data.data;
    console.log(dataWrapper);
    // console.log(dataWrapper.length);
    const lis = [];
    let listtemp = [];
    for (let i = 0; i < dataWrapper.length; i++) {
        if (listtemp.length === 0) {
            listtemp.push(<tr key={uuid()}>
                <th>번호</th>
                <th>정오</th>
                <th>내용영역</th>
                <th>행동영역</th>
                <th>정답률</th>
              </tr>
            );
        }
        listtemp.push(<tr key={uuid()}>
            <td>{dataWrapper[i].row.question_no}</td>
            <td>{dataWrapper[i].row.errata}</td>
            <td>{dataWrapper[i].row.info1}</td>
            <td>{dataWrapper[i].row.info2}</td>
            <td>{dataWrapper[i].row.correct}%</td>
          </tr>
        );
        if (i % 15 === 14 || i === dataWrapper.length - 1) {
            lis.push(listtemp);
            listtemp = [];
        }
    }

    return (
        <div>
            <div className="rwTableContainer MathrwTableContainer">
               <table><tbody>{lis[0]}</tbody></table>
               <table><tbody>{lis[1]}</tbody></table>
            </div>
            
        </div>
    );
}

export { RightWrongTable, IntegratedRightWrongTable, MathRightWrongTable };