import rating from "../../util/Rating";
import {round, round100, setMinimum} from "../../util/Math";
import {ScoreRow, ScoreSummary, EnglishAverageRow, MathAverageRow, TestTypeInfo, 
    NewScoreSummary, NewEnglishAverageSummary, PercentageSummary, ErrataRow, 
    SectorPercentageSummary, NewSectorEnglishAverageSummary, NationalPercentage, UpperlevelAvg, prevCurrExamData
} from "../dto/ReportDTO";

const GetScoreSummary = (raw: any, subject: string): ScoreSummary => {
    const score = raw.score;
    if (subject === 'english') {
        const data: ScoreSummary = {
            summary: {
                score_total: round(score.p_tot),
                cumulative_percentile_total: setMinimum(round(score.nu_tot)),
                level_percentile_total: setMinimum(round(score.lv_tot)),
                rating_total: score.r_tot,
                national_percentile_total: setMinimum(round(score.nat_nu)),
            },
            level: score.lv
        };
        return data;
    } else {
        const data: ScoreSummary = {
            summary: {
                score_total: round(score.total_score),
                cumulative_percentile_total: setMinimum(round100(score.sheet_percent)),
                level_percentile_total: setMinimum(round100(score.level_percent)),
                rating_total: rating(Number(round100(score.sheet_percent))).toString(),
                national_percentile_total: setMinimum(round100(score.nat_nu)),
            },
            level: score.level
        };
        return data;
    }
}

const GetEnglishScoreRow = (raw: any): ScoreRow[] => {
    const score = raw.score;

    const LC_basic = {
        row: {
            subject_name: "LC일반",
            score: round(score.LC_li_p),
            cumulative_percentile: setMinimum(round(score.LC_li_nu)),
            level_percentile: setMinimum(round(score.LC_li_lv)),
            rating: score.LC_li_r
        }
    }

    const LC_inference = {
        row: {
            subject_name: "LC추론",
            score: round(score.LC_chu_p),
            cumulative_percentile: setMinimum(round(score.LC_chu_nu)),
            level_percentile: setMinimum(round(score.LC_chu_lv)),
            rating: score.LC_chu_r
        }
    }

    const GR = {
        row: {
            subject_name: "GR",
            score: round(score.GR_p),
            cumulative_percentile: setMinimum(round(score.GR_nu)),
            level_percentile: setMinimum(round(score.GR_lv)),
            rating: score.GR_r
        }
    }

    const RC_basic = {
        row: {
            subject_name: "RC일반",
            score: round(score.RC_li_p),
            cumulative_percentile: setMinimum(round(score.RC_li_nu)),
            level_percentile: setMinimum(round(score.RC_li_lv)),
            rating: score.RC_li_r
        }
    }

    const RC_ksat = {
        row: {
            subject_name: "RC수능",
            score: round(score.RC_su_p),
            cumulative_percentile: setMinimum(round(score.RC_su_nu)),
            level_percentile: setMinimum(round(score.RC_su_lv)),
            rating: score.RC_su_r
        }
    }

    const RC_inference = {
        row: {
            subject_name: "RC추론",
            score: round(score.RC_chu_p),
            cumulative_percentile: setMinimum(round(score.RC_chu_nu)),
            level_percentile: setMinimum(round(score.RC_chu_lv)),
            rating: score.RC_chu_r
        }
    }

    const data: ScoreRow[] = [
        LC_basic, LC_inference, GR, RC_basic, RC_ksat, RC_inference
    ]

    return data;
}

const GetEnglishAverageGraphData = (report: any) => {
    const myGrade = report.score;

    const level = myGrade.lv
    const grades = Object.keys(report).filter((key) => {
        return key.split("_")[0] === myGrade.grade
    })
    const grade = grades.length > 0 ? myGrade.grade : '1'

    const levels = Object.keys(report).filter((key) => {
        return key[0] === grade
    }).map((key) => { return key.split("_")[1] })

    const sameLevelGrade = report[`${grade}_${level}`];
    const comparsionLevelGrade =
    report[`${grade}_${GetEnglishComparsionLevel(level, levels)}`];

    const data = [
        {
            name: "LC일반",
            myScore: round(myGrade.LC_li_p),
            sameLevelAverage: round(sameLevelGrade.lc_il),
            comparisonAverage: round(comparsionLevelGrade.lc_il),
        },
        {
            name: "LC추론",
            myScore: round(myGrade.LC_chu_p),
            sameLevelAverage: round(sameLevelGrade.lc_chu),
            comparisonAverage: round(comparsionLevelGrade.lc_chu),
        },
        {
            name: "GR",
            myScore: round(myGrade.GR_p),
            sameLevelAverage: round(sameLevelGrade.gr),
            comparisonAverage: round(comparsionLevelGrade.gr),
        },
        {
            name: "RC일반",
            myScore: round(myGrade.RC_li_p),
            sameLevelAverage: round(sameLevelGrade.rc_il),
            comparisonAverage: round(comparsionLevelGrade.rc_il),
        },
        {
            name: "RC수능",
            myScore: round(myGrade.RC_su_p),
            sameLevelAverage: round(sameLevelGrade.rc_su),
            comparisonAverage: round(comparsionLevelGrade.rc_su),
        },
        {
            name: "RC추론",
            myScore: round(myGrade.RC_chu_p),
            sameLevelAverage: round(sameLevelGrade.rc_chu),
            comparisonAverage: round(comparsionLevelGrade.rc_chu),
        },
    ];

    return {
        data: data,
        level: GetLevelName(level, 'english'),
        comparisonLevel: GetLevelName(GetEnglishComparsionLevel(level, levels), 'english'),
    };
}

const GetEnglishAverageTableData = (raw: any, grade: string): EnglishAverageRow[] => {
    const keys = Object.keys(raw);

    const filteredKeys = keys.filter(key => key.split('_')[0] === grade);
    const result: EnglishAverageRow[] = [];

    console.log(grade)

    filteredKeys.forEach(key => {
        const data = raw[key];
        const level = key.split('_')[1];
        const row: EnglishAverageRow = {
            row: {
                class_name: GetLevelName(Number(level), 'english'),
                LC_basic: round(data.lc_il),
                LC_inference: round(data.lc_chu),
                GR: round(data.gr),
                RC_basic: round(data.rc_il),
                RC_ksat: round(data.rc_su),
                RC_inference: round(data.rc_chu),
                total: round(data.tot)
            }
        };
        result.push(row);
    });

    return result;
}

const GetMathScoreBySubjectRow = (raw: any): ScoreRow[] => {
    const score = raw.score;
    const unitKeys: string[] = Object.keys(raw.unit_names);

    const data: ScoreRow[] = []

    for (const key of unitKeys) {
        const name = raw.unit_names[key];
        data.push({
            row: {
                subject_name: name,
                score: round(score[`${key}_score`]),
                cumulative_percentile: setMinimum(round100(score[`${key}_score_p`])),
                level_percentile: setMinimum(round100(score[`${key}_score_pl`])),
                rating: rating(Number(score[`${key}_score_p`]) * 100).toString()
            }
        })
    }

    return data;
}

const GetMathScoreByDifficultyRow = (raw: any): ScoreRow[] => {
    const score = raw.score;
    const diffKeys: string[] = ['diff2', 'diff3', 'diff4', 'diff5'];
    const diffNames: string[] = ['기본유형', '응용유형', '추론유형', '심화유형']

    const data: ScoreRow[] = []

    for (const i in diffKeys) {
        const key = diffKeys[i];
        const name = diffNames[i];
        data.push({
            row: {
                subject_name: name,
                score: round(score[`${key}_score`]),
                cumulative_percentile: setMinimum(round100(score[`${key}_score_p`])),
                level_percentile: setMinimum(round100(score[`${key}_score_pl`])),
                rating: rating(Number(score[`${key}_score_p`]) * 100).toString()
            }
        })
    }

    return data;
}

const GetMathAverageGraphData = (report: any) => {
    const myGrade = report.score;
    const levels = Object.keys(report.score_avg)
    const sameLevelGrade = report.score_avg[myGrade.level];
    const compLv = GetMathComparsionLevel(myGrade.level, levels)
    const comparsionLevelGrade = report.score_avg[compLv]

    const data = [
        {
            name: "기본유형",
            myScore: round(myGrade.diff2_score),
            sameLevelAverage: round(sameLevelGrade.diff2_score),
            comparisonAverage: round(comparsionLevelGrade.diff2_score),
        },
        {
            name: "응용유형",
            myScore: round(myGrade.diff3_score),
            sameLevelAverage: round(sameLevelGrade.diff3_score),
            comparisonAverage: round(comparsionLevelGrade.diff3_score),
        },
        {
            name: "추론유형",
            myScore: round(myGrade.diff4_score),
            sameLevelAverage: round(sameLevelGrade.diff4_score),
            comparisonAverage: round(comparsionLevelGrade.diff4_score),
        },
        {
            name: "심화유형",
            myScore: round(myGrade.diff5_score),
            sameLevelAverage: round(sameLevelGrade.diff5_score),
            comparisonAverage: round(comparsionLevelGrade.diff5_score),
        }
    ];

    return {
        data: data,
        level: GetLevelName(myGrade.level, 'math'),
        comparisonLevel: GetLevelName(GetMathComparsionLevel(myGrade.level, levels), 'math'),
    };
}

const GetMathAverageTableData = (raw: any): MathAverageRow[] => {
    const levels = Object.keys(raw.score_avg);

    const result: MathAverageRow[] = [];

    for (const level of levels) {
        const data = raw.score_avg[level];
        const row: MathAverageRow = {
            row: {
                class_name: GetLevelName(Number(level), 'math'),
                diff2: round(data.diff2_score),
                diff3: round(data.diff3_score),
                diff4: round(data.diff4_score),
                diff5: round(data.diff5_score)
            }
        };
        result.push(row);
    }

    return result;
}

function GetLevelName(level: number, subject: string) {
    const englishLevelNameList = [
        "",
        "0.1%",
        "Harvard",
        "Stanford",
        "Yale",
        "Princeton",
        "Col",
        "Duke",
        "특목 P-B",
        "특목 P-A",
        "Advanced",
        "Intermediate",
    ];

    const mathLevelNameList = [
        "",
        "0.1%",
        "의대",
        "특목",
        "MS",
        "MA",
        "MB",
        "MC"
    ];

    if (subject === 'english') {
        return englishLevelNameList[level];
    } else if (subject === 'math') {
        return mathLevelNameList[level];
    }
    return ""
}

function GetClassName(level: number, subject: string) {
    if (subject === 'english') {
        if (level <= 7) return "특목관"
        else return "중등관"
    } else if (subject === 'math') {
        if (level < 4) return "특목관"
        else return "중등관"
    }

    return ""
}

function GetEnglishComparsionLevel(myLevel: string, levels: string[]) {
//    const level = Number(levelRaw);
//    if (level === 1) return 2;
//    if (level === 8) return 9;
//    return level - 1;
    if (levels.length === 1) return Number(myLevel)

    let i = 0;
    for (const level of levels) {
        if (level === myLevel) {
            if (i !== 0) {
                return Number(levels[i - 1])
            }
            return Number(levels[1])
        }
        i++
    }
    return Number(myLevel)
}

function GetMathComparsionLevel(levelRaw: string, levels: string[]) {
    return GetElementaryMathComparsionLevel(levelRaw, levels)
}

function GetElementaryMathComparsionLevel(myLevel: string, levels: string[]) {
    if (levels.length === 1) return Number(myLevel)

    let i = 0;
    for (const level of levels) {
        if (level === myLevel) {
            if (i !== 0) {
                return Number(levels[i - 1])
            }
            return Number(levels[1])
        }
        i++
    }
    return Number(myLevel)
}

const GetTestTypeName = (testType: number, subject: string, stuGrade: number, stuLevel: number): TestTypeInfo => {
    const englishTestTypeNameList = [
        "", 
        "통합형 일반", 
        "통합형 특목", 
        "고1 수능형", 
        "고2 수능형", 
        "고3 수능형" 
    ];
    const englishTestTypeGradeList = [
        "", "", "", "1", "2", "3"
    ];
    if (subject === 'english') {
        let index = testType;
        if (stuGrade > 3) index = 2;

        const data: TestTypeInfo = {
            name: englishTestTypeNameList[index],
            grade: englishTestTypeGradeList[index]
        };
        return data;
    }

    // console.log(stuLevel);
    // console.log(subject);
    let mathTestTypeName = "일반형";
    if (stuLevel < 4) mathTestTypeName = "특목형";

    const data: TestTypeInfo = {
        name: mathTestTypeName,
        grade: ""
    };
    return data;
}


const GetNewScoreSummary = (raw: any, subject: string): NewScoreSummary => {
    console.log(raw);
    const score = raw.score;
    const sheetScoreList = ["", "660", "600", "100", "100", "100"];

    let total_score = sheetScoreList[+score.test_type];
    if (raw.errata.length === 55) total_score = "120";

    if (subject === 'english') {
        const data: NewScoreSummary = {
            summary: {
                score: score.total_score,
                total_score: total_score,
                total_rank: score.tot_rank,
                total_rank_total: score.rank_tot,
                grade_rank: score.grade_rank,
                grade_rank_total: score.grade_rank_tot,
                level_rank: "",
                level_rank_total: ""
            },
            level: score.lv,
            testType: +score.test_type
        };
        return data;
    } 
    const data: NewScoreSummary = {
        summary: {
            score: score.total_score,
            total_score: "100",
            total_rank: score.tot_rank,
            total_rank_total: score.rank_tot,
            grade_rank: score.grade_rank,
            grade_rank_total: score.grade_rank_tot,
            level_rank: score.lv_rank,
            level_rank_total: score.lv_rank_tot
        },
        level: score.lv,
        testType: +score.test_type
    };
    return data;
}

const GetNewAverageGraphData = (raw: any, subject: string): NewEnglishAverageSummary =>{
    const chartRange = ["", '780', '720', '120', '120', '120'];
    if (subject === 'english') {
            const data: NewEnglishAverageSummary = {
                myscore: raw.score.total_score,
                totalScore: chartRange[+raw.score.test_type],
                sheetAvg: parseFloat(raw.avg.by_sheet).toFixed(2),
                gradeAvg: parseFloat(raw.avg.by_grade).toFixed(2),
                lvAvg: parseFloat(raw.avg.by_lv).toFixed(2),
                lvGradeAvg: parseFloat(raw.avg.by_grade_lv).toFixed(2),
                testType: raw.score.test_type
            }
            return data;
        
    }
    const data: NewEnglishAverageSummary = {
        myscore: raw.score.total_score,
        totalScore: "120",
        sheetAvg: parseFloat(raw.avg.by_sheet).toFixed(2),
        gradeAvg: parseFloat(raw.avg.by_grade).toFixed(2),
        lvAvg: parseFloat(raw.avg.by_lv).toFixed(2),
        lvGradeAvg: parseFloat(raw.avg.by_grade_lv).toFixed(2),
        testType: raw.score.test_type
    }
    return data;
}

const GetPercentageSummary = (raw: any, subject: string): PercentageSummary =>{
    const transformValue = (value: string) => {
        return value === "0.00" ? "0.01" : value;
    }

    const score = raw.score;
    if (subject === 'english') {
        const data: PercentageSummary = {
            sheetPercentage: transformValue(parseFloat(score.sheet_percent).toFixed(2)),
            gradePercentage: transformValue(parseFloat(score.grade_percent).toFixed(2)),
            lvPercentage: transformValue(parseFloat(score.lv_percent).toFixed(2)),
            lvGradePercentage: transformValue(parseFloat(score.grade_lv_percent).toFixed(2))
        }
        // console.log(data);
        return data;
        
        
    }
    const data: PercentageSummary = {
        sheetPercentage: transformValue(parseFloat(score.sheet_percent).toFixed(2)),
            gradePercentage: transformValue(parseFloat(score.grade_percent).toFixed(2)),
            lvPercentage: transformValue(parseFloat(score.lv_percent).toFixed(2)),
            lvGradePercentage: transformValue(parseFloat(score.grade_lv_percent).toFixed(2))
    }
    return data;
}

const GetErrataTableData = (raw: any, subject: string): ErrataRow[] => {
    const errata = raw.errata;
    const info = raw.info;

    // console.log(errata.length);

    const result: ErrataRow[] = [];

    for (let i = 1; i <= +errata.length; i++) {
        const row: ErrataRow = {
            row: {
                question_no: info[i].question_no,
                correct: parseFloat(info[i].correct).toFixed(1),
                points: info[i].points,
                info1: info[i].info1,
                info2: info[i].info2,
                errata: (errata[i - 1] === "1") ? "O" : "X"
            }
        }
        result.push(row);
    }

    return result;

}

const GetSectorAverageGraphData = (raw: any): NewSectorEnglishAverageSummary => {
    const data : NewSectorEnglishAverageSummary = {
        GR_by_grade: parseFloat(raw.avg.GR_by_grade).toFixed(1),
        GR_by_lv: parseFloat(raw.avg.GR_by_lv).toFixed(1),
        LC_chu_by_grade: parseFloat(raw.avg.LC_chu_by_grade).toFixed(1),
        LC_chu_by_lv: parseFloat(raw.avg.LC_chu_by_lv).toFixed(1),
        LC_il_by_grade: parseFloat(raw.avg.LC_il_by_grade).toFixed(1),
        LC_il_by_lv: parseFloat(raw.avg.LC_il_by_lv).toFixed(1),
        RC_chu_by_grade: parseFloat(raw.avg.RC_chu_by_grade).toFixed(1),
        RC_chu_by_lv: parseFloat(raw.avg.RC_chu_by_lv).toFixed(1),
        RC_il_by_grade: parseFloat(raw.avg.RC_il_by_grade).toFixed(1),
        RC_il_by_lv: parseFloat(raw.avg.RC_il_by_lv).toFixed(1),
        RC_su_by_grade: parseFloat(raw.avg.RC_su_by_grade).toFixed(1),
        RC_su_by_lv: parseFloat(raw.avg.RC_su_by_lv).toFixed(1),
        my_LC_il_score: raw.score.LC_il,
        my_LC_chu_score: raw.score.LC_chu,
        my_GR_score: raw.score.GR,
        my_RC_il_score: raw.score.RC_il,
        my_RC_su_score: raw.score.RC_su,
        my_RC_chu_score: raw.score.RC_chu
    }
    console.log(data);
    return data;
}

const GetSectorPercentageSummary = (raw: any): SectorPercentageSummary => {

    const transformValue = (value: string) => {
        return value === "0.0" ? "0.1" : value;
    }
    if (!raw.percentile) {
        const nulldata: SectorPercentageSummary = {
            GR_by_grade: null,
            GR_by_lv: null,
            LC_chu_by_grade: null,
            LC_chu_by_lv: null,
            LC_il_by_grade: null,
            LC_il_by_lv: null,
            RC_chu_by_grade: null,
            RC_chu_by_lv: null,
            RC_il_by_grade: null,
            RC_il_by_lv: null,
            RC_su_by_grade: null,
            RC_su_by_lv: null
        }

        return nulldata;
    }

    const data: SectorPercentageSummary = {
        GR_by_grade: transformValue(parseFloat(raw.percentile.GR_by_grade).toFixed(1)),
        GR_by_lv: transformValue(parseFloat(raw.percentile.GR_by_lv).toFixed(1)),
        LC_chu_by_grade: transformValue(parseFloat(raw.percentile.LC_chu_by_grade).toFixed(1)),
        LC_chu_by_lv: transformValue(parseFloat(raw.percentile.LC_chu_by_lv).toFixed(1)),
        LC_il_by_grade: transformValue(parseFloat(raw.percentile.LC_il_by_grade).toFixed(1)),
        LC_il_by_lv: transformValue(parseFloat(raw.percentile.LC_il_by_lv).toFixed(1)),
        RC_chu_by_grade: transformValue(parseFloat(raw.percentile.RC_chu_by_grade).toFixed(1)),
        RC_chu_by_lv: transformValue(parseFloat(raw.percentile.RC_chu_by_lv).toFixed(1)),
        RC_il_by_grade: transformValue(parseFloat(raw.percentile.RC_il_by_grade).toFixed(1)),
        RC_il_by_lv: transformValue(parseFloat(raw.percentile.RC_il_by_lv).toFixed(1)),
        RC_su_by_grade: transformValue(parseFloat(raw.percentile.RC_su_by_grade).toFixed(1)),
        RC_su_by_lv: transformValue(parseFloat(raw.percentile.RC_su_by_lv).toFixed(1))
    }
  
    // console.log(data);
    return data;
}

const GetNationalPercentage = (raw: any): NationalPercentage => {
    // console.log(raw);
    const transformValue = (value: string) => {
        return value === "0.0" ? "0.1" : value;
    }

    const data : NationalPercentage = {
        national_nu: transformValue(parseFloat(raw.nat_nu).toFixed(1)),
        highschool_nu: transformValue(parseFloat(raw.highschool_nu).toFixed(1))
    }
    // console.log(data);
    return data;
}

const GetUpperlevelAvg = (raw:any): UpperlevelAvg => {
    if (raw) {
        const data : UpperlevelAvg = {
            lv: raw.lv,
            avg: parseFloat(raw.by_lv).toFixed(1)
        }
        return data;
    } else {
        const data : UpperlevelAvg = {
            lv: null,
            avg: null
        }
        return data;
    }
}

const GetSuneongRanking = (score: number) => {
    let rank = 0;
    if (score >= 100) rank = 1;
    else if (score < 10) rank = 9;
    else rank = Math.ceil((100 - score) / 10);
    return rank;
}

const GetPrevExamExistance = (raw: any) => {
    // console.log(raw.prev_exam);

    let exist = false;
    if ('prev_exam' in raw) exist = true;
    
    return exist;
}

const GetPrevExamData = (currentdata:any, prevdata:any, currTestType:string) => {
    prevdata = prevdata[0];
    console.log(prevdata);
    console.log(currentdata);

    if (prevdata.GR === null) { // prev = 수능형
        const difftype = currTestType === "integrated" ? true : false; // 이번이랑 저번이랑 시험 유형이 다르면

        const data : prevCurrExamData = {
            testtype: "suneong",
            prev_total_score: prevdata.total_score,
            prev_LC_il_score: "",
            prev_LC_chu_score: "",
            prev_GR_score: "",
            prev_RC_il_score: "",
            prev_RC_su_score: "",
            prev_RC_chu_score: "",
            prev_grade_percent: parseFloat(prevdata.grade_percent).toFixed(1),
            score_change: difftype ? "0" : (parseFloat(currentdata.total_score) - parseFloat(prevdata.total_score)).toFixed(1), // 타입이 같을 때만 비교
            LC_il_change: "",
            LC_chu_change: "",
            GR_change: "",
            RC_il_change: "",
            RC_su_change: "",
            RC_chu_change: "",
            percent_change: difftype ? "0" : (parseFloat(parseFloat(currentdata.grade_percent).toFixed(1)) - parseFloat(parseFloat(prevdata.grade_percent).toFixed(1))).toFixed(1)
        }

        return data;
    }

    const difftype = currTestType === "suneong" ? true : false;

    const data : prevCurrExamData = { // prev = 통합형
        testtype: "integrated",
        prev_total_score: prevdata.total_score,
        prev_LC_il_score: prevdata.LC_il,
        prev_LC_chu_score: prevdata.LC_chu,
        prev_GR_score: prevdata.GR,
        prev_RC_il_score: prevdata.RC_il,
        prev_RC_su_score: prevdata.RC_su,
        prev_RC_chu_score: prevdata.RC_chu,
        prev_grade_percent: "",
        score_change: difftype ? "0" : (parseFloat(currentdata.total_score) - parseFloat(prevdata.total_score)).toFixed(1),
        LC_il_change: difftype ? "0" : (parseFloat(currentdata.LC_il) - parseFloat(prevdata.LC_il)).toFixed(1),
        LC_chu_change: difftype ? "0" : (parseFloat(currentdata.LC_chu) - parseFloat(prevdata.LC_chu)).toFixed(1),
        GR_change: difftype ? "0" : (parseFloat(currentdata.GR) - parseFloat(prevdata.GR)).toFixed(1),
        RC_il_change: difftype ? "0" : (parseFloat(currentdata.RC_il) - parseFloat(prevdata.RC_il)).toFixed(1),
        RC_su_change: difftype ? "0" : (parseFloat(currentdata.RC_su) - parseFloat(prevdata.RC_su)).toFixed(1),
        RC_chu_change: difftype ? "0" : (parseFloat(currentdata.RC_chu) - parseFloat(prevdata.RC_chu)).toFixed(1),
        percent_change: ""
    }

    return data;

}

export {
    GetScoreSummary,
    GetEnglishScoreRow,
    GetEnglishAverageGraphData,
    GetEnglishAverageTableData,
    GetMathScoreBySubjectRow,
    GetMathScoreByDifficultyRow,
    GetMathAverageGraphData,
    GetMathAverageTableData,
    GetLevelName,
    GetClassName,
    GetTestTypeName,
    GetNewScoreSummary,
    GetNewAverageGraphData,
    GetPercentageSummary,
    GetErrataTableData,
    GetSectorAverageGraphData,
    GetSectorPercentageSummary,
    GetNationalPercentage,
    GetUpperlevelAvg,
    GetSuneongRanking,
    GetPrevExamExistance,
    GetPrevExamData
};