import React from "react";
import './SubjectInfo.scss'
import HearingIcon from '../../../resource/hearing.svg'
import ABCIcon from '../../../resource/abc.svg'
import ReadingIcon from '../../../resource/library_books.svg'

interface SubjectCardProp {
    icon: string
    title: string
    content: string
}

const SubjectCard = ({icon, title, content}: SubjectCardProp) => {
    return (
        <div className="SubjectCard">
            <img className="SubjectCard-img" src={icon} alt="icon"/>
            <p className="SubjectCard-title">{title}</p>
            <p className="SubjectCard-content">{content}</p>
        </div>
    )
}

interface SubjectInfoProp {
    scores: {
        lc_general_score: number
        lc_inference_score: number
        grammer_score: number
        rc_general_score: number
        rc_ksat_score: number
        rc_inference_score: number
    }
}

const SubjectInfo = ({scores}: SubjectInfoProp) => {
    return (
        <div className="SubjectInfo">
            <div className="SubjectInfo-card">
                <SubjectCard icon={HearingIcon} title="LC 일반"
                             content="기본적인 영어 듣기 능력을 평가하는 영역으로, 영어 듣기를 듣고 단어들을 이용해서 내용을 유추하는 영역입니다."/>
                <div className="SubjectCard-score">총점 {scores.lc_general_score}점</div>
            </div>
            <div className="SubjectInfo-card">
                <SubjectCard icon={HearingIcon} title="LC 추론"
                             content="영어 듣기를 하는 과정에서 전반적인 맥락을 파악하여 활용할 수 있는 능력을 평가하는 영역입니다."/>
                <div className="SubjectCard-score">총점 {scores.lc_inference_score}점</div>
            </div>
            <div className="SubjectInfo-card">
                <SubjectCard icon={ABCIcon} title="GR" content="문법 영역에서 옳고 그름을 판단 할 수 있는지 평가하는 영역입니다."/>
                <div className="SubjectCard-score">총점 {scores.grammer_score}점</div>
            </div>
            <div className="SubjectInfo-card">
                <SubjectCard icon={ReadingIcon} title="RC 일반" content="단순하게 글을 읽고 문맥에 관계없이 글을 해석할 수 있는지 평가하는 영역입니다."/>
                <div className="SubjectCard-score">총점 {scores.rc_general_score}점</div>
            </div>
            <div className="SubjectInfo-card">
                <SubjectCard icon={ReadingIcon} title="RC 수능"
                             content="수능 유형을 연습하기 위한 영역으로, 수능에서 요구하는 추론적 사고를 평가하면서 단문에 집약된 정보를 읽어낼 수 있는지 평가하는 영역입니다."/>
                <div className="SubjectCard-score">총점 {scores.rc_ksat_score}점</div>
            </div>
            <div className="SubjectInfo-card">
                <SubjectCard icon={ReadingIcon} title="RC 추론"
                             content="수능보다는 비교적 긴 장문을 전반적으로 이해할 수 있는지 확인하는 영역입니다. 글을 읽는 능력 뿐만 아니라 전반적인 맥락을 토대로 글의 핵심을 추론해 낼 수 있는 지 평가하는 영역입니다."/>
                <div className="SubjectCard-score">총점 {scores.rc_inference_score}점</div>
            </div>
        </div>
    )
}

export default SubjectInfo;