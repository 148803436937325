import "./ReportHeader.scss";
import logo from "../../../resource/cnc_logo.png";
import { useNavigate } from "react-router-dom";
import StuInfo from "../../../../data/dto/TeacherInfo";

interface ReportHeaderProp {
  info: StuInfo;
}

const ReportHeader = ({ info }: ReportHeaderProp) => {
  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem("jwt");
    navigate("/teacher");
  };

  return (
    <div className="ReportHeader-container">
      <div className="ReportHeader-text start">{info.test.name}</div>
      <div className="ReportHeader-logo-contanier">
        <img className="ReportHeader-logo" src={logo} alt="logo" />
      </div>
      <div className="ReportHeader-text end">
        <p className="Change-Password" onClick={logout}>
          로그아웃
        </p>
        <p>{info.teacherName} 선생님</p>
      </div>
    </div>
  );
};

export default ReportHeader;
