import React, { useState, useEffect } from 'react';
import { inputEvent } from '../Event/type'
import './Inputfield.scss'

interface TextfieldProps {
  type: string;
  name: string;
  placeholder: string;
  onChange: inputEvent;
}

interface PhonefieldProps {
  name: string;
  placeholder: string;
  onChange: inputEvent;
}

const Textfield = ({type = 'text', name, placeholder, onChange}: TextfieldProps) => {
  return (
    <input 
      type={type}
      className="Textfield"
      name={name}
      onChange={onChange}
      placeholder={placeholder}
    />
  )
}

const Phonefield = ({name, placeholder, onChange}: PhonefieldProps) => {
  const [phoneNumber, setPhoneNumber] = useState("");

  const handlePress = (e:React.ChangeEvent<HTMLInputElement>) => {
    const regex = /^[0-9\b -]{0,13}$/;
    if (regex.test(e.target.value)) {
      setPhoneNumber(e.target.value);
    }
    onChange(e);    
  }

  useEffect(() => {
    if (phoneNumber.length === 10) {
      setPhoneNumber(phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'));
    }
    if (phoneNumber.length === 13) {
      setPhoneNumber(phoneNumber.replace(/-/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'));
    }
  }, [phoneNumber]);

  return (
    <input 
      type='text'
      className="Textfield"
      name={name}
      onChange={handlePress}
      placeholder={placeholder}
      value={phoneNumber}
    />
  )
}

export { Textfield, Phonefield };