import React from "react"
import { BarChart, Bar, XAxis, YAxis, Cell, LabelList, ResponsiveContainer, Rectangle } from 'recharts';
import {v4 as uuid} from "uuid";


interface ChartDataItem {
    name: string;
    uv: number;
    color: string;
}

const chartData: ChartDataItem[] = [
    {name: "내 점수", uv: 99, color: 'rgba(255, 5, 5, 0.65)'},
    {name: "레벨평균", uv: 80, color: 'rgba(195, 0, 0, 0.15)'},
    {name: "학년평균", uv: 75, color: 'rgba(195, 0, 0, 0.15)'},
]

const CustomBar: React.FC<any> = (props) => {
    return (
        <>
      <defs>
        <filter id="shadow" x="-20%" y="-20%" width="140%" height="140%">
          <feGaussianBlur in="SourceAlpha" stdDeviation="3 8" />
          <feOffset dx="0" dy="4" result="offsetblur" />
          <feComponentTransfer>
            <feFuncA type="table" tableValues="0 0.6" />
          </feComponentTransfer>
          <feMerge>
            <feMergeNode />
            <feMergeNode in="SourceGraphic" />
          </feMerge>

        </filter>
      </defs>
      <Rectangle {...props} filter="url(#shadow)" />
    </>
    );
}



const renderCustomLabel: React.FC<any> = (props) => {
    const { x, y, value, index, width } = props;
    const entry = chartData[index];
    const name = entry.name;
    let fontSize: string;
  
    // name 값에 따른 글자 크기 설정
    if (name === '내 점수') {
      fontSize = '20px';
    } else {
      fontSize = '18px';
    }

    const xOffset = value > 100 ? -13 : -7;

    return (
        <text x={x + xOffset} y={y - 10} fill="black" fontSize={fontSize}>
            {value}
        </text>
    );
  };

const LCilAverageGraphData = (data: any) => {
    const dataWrapper = data.data;
    // console.log(dataWrapper);
    const uvValuesList: string[] = [
        dataWrapper.my_LC_il_score, dataWrapper.LC_il_by_lv, dataWrapper.LC_il_by_grade
    ];
    const updatedChartData: ChartDataItem[] = chartData.map((item, index) => ({
        ...item,
        uv: +uvValuesList[index]
    }));



    return (
        <div>
           <ResponsiveContainer width="99%" height={400}>
              <BarChart data={updatedChartData} margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
              <XAxis type="category" hide={true}/>
              <YAxis type="number" hide={true} domain={[0, 'dataMax+15']}/>
              <Bar dataKey="uv" barSize={20} shape={<CustomBar/>}>
                  {chartData.map((entry) => (
                  <Cell fill={entry.color} key={uuid()}/>
                  ))}
                  <LabelList dataKey="uv" position="top" offset={10} content={renderCustomLabel} />
              </Bar>
              </BarChart>
          </ResponsiveContainer>
        </div>
    );
}

const LCchuAverageGraphData = (data: any) => {
    const dataWrapper = data.data;
    const uvValuesList: string[] = [
        dataWrapper.my_LC_chu_score, dataWrapper.LC_chu_by_lv, dataWrapper.LC_chu_by_grade
    ];
    const updatedChartData: ChartDataItem[] = chartData.map((item, index) => ({
        ...item,
        uv: +uvValuesList[index]
    }));
    return (
        <div>
            <ResponsiveContainer width="99%" height={400}>
              <BarChart data={updatedChartData} margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
              <XAxis type="category" hide={true}/>
              <YAxis type="number" hide={true} domain={[0, 'dataMax+15']}/>
              <Bar dataKey="uv" barSize={20} shape={<CustomBar/>}>
                  {chartData.map((entry) => (
                  <Cell fill={entry.color} key={uuid()}/>
                  ))}
                  <LabelList dataKey="uv" position="top" offset={10} content={renderCustomLabel} />
              </Bar>
              </BarChart>
          </ResponsiveContainer>
        </div>
    );
}

const GRAverageGraphData = (data: any) => {
    const dataWrapper = data.data;
    const uvValuesList: string[] = [
        dataWrapper.my_GR_score, dataWrapper.GR_by_lv, dataWrapper.GR_by_grade
    ];
    const updatedChartData: ChartDataItem[] = chartData.map((item, index) => ({
        ...item,
        uv: +uvValuesList[index]
    }));
    return (
        <div>
            <ResponsiveContainer width="99%" height={400}>
              <BarChart data={updatedChartData} margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
              <XAxis type="category" hide={true}/>
              <YAxis type="number" hide={true} domain={[0, 'dataMax+15']}/>
              <Bar dataKey="uv" barSize={20} shape={<CustomBar/>}>
                  {chartData.map((entry) => (
                  <Cell fill={entry.color} key={uuid()}/>
                  ))}
                  <LabelList dataKey="uv" position="top" offset={10} content={renderCustomLabel} />
              </Bar>
              </BarChart>
          </ResponsiveContainer>
        </div>
    );
}

const RCilAverageGraphData = (data: any) => {
    const dataWrapper = data.data;
    const uvValuesList: string[] = [
        dataWrapper.my_RC_il_score, dataWrapper.RC_il_by_lv, dataWrapper.RC_il_by_grade
    ];
    const updatedChartData: ChartDataItem[] = chartData.map((item, index) => ({
        ...item,
        uv: +uvValuesList[index]
    }));
    return (
        <div>
           <ResponsiveContainer width="99%" height={400}>
              <BarChart data={updatedChartData} margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
              <XAxis type="category" hide={true}/>
              <YAxis type="number" hide={true} domain={[0, 'dataMax+15']}/>
              <Bar dataKey="uv" barSize={20} shape={<CustomBar/>}>
                  {chartData.map((entry) => (
                  <Cell fill={entry.color} key={uuid()}/>
                  ))}
                  <LabelList dataKey="uv" position="top" offset={10} content={renderCustomLabel} />
              </Bar>
              </BarChart>
          </ResponsiveContainer>
        </div>
    );
}

const RCsuAverageGraphData = (data: any) => {
    const dataWrapper = data.data;
    const uvValuesList: string[] = [
        dataWrapper.my_RC_su_score, dataWrapper.RC_su_by_lv, dataWrapper.RC_su_by_grade
    ];
    const updatedChartData: ChartDataItem[] = chartData.map((item, index) => ({
        ...item,
        uv: +uvValuesList[index]
    }));
    return (
        <div>
            <ResponsiveContainer width="99%" height={400}>
              <BarChart data={updatedChartData} margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
              <XAxis type="category" hide={true}/>
              <YAxis type="number" hide={true} domain={[0, 'dataMax+15']}/>
              <Bar dataKey="uv" barSize={20} shape={<CustomBar/>}>
                  {chartData.map((entry) => (
                  <Cell fill={entry.color} key={uuid()}/>
                  ))}
                  <LabelList dataKey="uv" position="top" offset={10} content={renderCustomLabel} />
              </Bar>
              </BarChart>
          </ResponsiveContainer>
        </div>
    );
}

const RCchuAverageGraphData = (data: any) => {
    const dataWrapper = data.data;
    const uvValuesList: string[] = [
        dataWrapper.my_RC_chu_score, dataWrapper.RC_chu_by_lv, dataWrapper.RC_chu_by_grade
    ];
    const updatedChartData: ChartDataItem[] = chartData.map((item, index) => ({
        ...item,
        uv: +uvValuesList[index]
    }));
    return (
        <div>
           <ResponsiveContainer width="99%" height={400}>
              <BarChart data={updatedChartData} margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
              <XAxis type="category" hide={true}/>
              <YAxis type="number" hide={true} domain={[0, 'dataMax+15']}/>
              <Bar dataKey="uv" barSize={20} shape={<CustomBar/>}>
                  {chartData.map((entry) => (
                  <Cell fill={entry.color} key={uuid()}/>
                  ))}
                  <LabelList dataKey="uv" position="top" offset={10} content={renderCustomLabel} />
              </Bar>
              </BarChart>
          </ResponsiveContainer>
        </div>
    );
}




export {LCilAverageGraphData, LCchuAverageGraphData, GRAverageGraphData, RCilAverageGraphData, RCsuAverageGraphData, RCchuAverageGraphData};