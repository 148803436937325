import React from "react";
import {EnglishAverageRow} from "../../../../data/dto/ReportDTO";
import {v4 as uuid} from "uuid";
import './AverageTable.scss'


interface EnglishAverageTableProp {
    rows: EnglishAverageRow[]
    title: string
}

const EnglishAverageTable = ({rows, title}: EnglishAverageTableProp) => {
    if (rows.length < 1) return (null)

    return (
        <div>
            <p className="AverageTable-title">{title}</p>
            <table className="AverageTable">
                <tbody>
                <tr className="AverageTable-header">
                    <td>반\영역</td>
                    <td>LC일반</td>
                    <td>LC추론</td>
                    <td>GR</td>
                    <td>RC일반</td>
                    <td>RC수능</td>
                    <td>RC추론</td>
                    <td>총점</td>
                </tr>
                {rows.map(
                    ({row}: EnglishAverageRow) => (
                        <tr className="AverageTable-row" key={uuid()}>
                            <td>{row.class_name}</td>
                            <td>{row.LC_basic}</td>
                            <td>{row.LC_inference}</td>
                            <td>{row.GR}</td>
                            <td>{row.RC_basic}</td>
                            <td>{row.RC_ksat}</td>
                            <td>{row.RC_inference}</td>
                            <td>{row.total}</td>
                        </tr>
                    )
                )}
                </tbody>
            </table>
        </div>
    )
}

export default EnglishAverageTable;