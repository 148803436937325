import React, { useState, useEffect } from "react";
import ReactGA from "react-ga";
import Modal from "react-modal";
import { Link, useNavigate } from "react-router-dom";

// import data layer
import getReport from "../../../data/repository/ReportRepository";
import { isReportVaild } from "../../../data/usecase/AuthUsecase";

//import { getOptions } from '../../../data/usecase/TestUsecase';
import Option from "../../../data/dto/Option";
import { LoginInfo, NewLoginInfo } from "../../../data/dto/LoginInfo";

// import ui component
import Button from "../../component/Button/Button";
import { Textfield, Phonefield } from "../../component/Inputfield/Inputfield";
import Select from "../../component/Select/Select";

// import resource
import logo from "../../resource/cnc_logo.png";
import "./LoginPage.scss";
import { getTestData } from "../../../data/repository/AuthRepository";
import SubjectDictionary from "../../../util/SubjectDictionary";

interface LoginInputProp {
  onPasswordClicked: Function;
}

// 모달 스타일
const modalStyle: Modal.Styles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 10,
    backdropFilter: "blur(10px)",
  },
  content: {
    display: "flex",
    position: "static",
    justifyContent: "center",
    background: "#F4F8FE",
    overflow: "auto",
    width: "fit-content",
    height: "fit-content",
    WebkitOverflowScrolling: "touch",
    borderRadius: "14px",
    border: "none",
    outline: "none",
    zIndex: 10,
  },
};

type OptionsData = {
  yearOptions: Option[];
  quaterOptions: Option[];
  subjectOptions: Option[];
};

const defalutOptions: OptionsData = {
  yearOptions: [{ name: " ", key: " " }],
  quaterOptions: [{ name: " ", key: " " }],
  subjectOptions: [{ name: " ", key: " " }],
};

const processOptions = (
  raw: any,
  selectedYear: string | null,
  selectedQuater: string | null
) => {

  const years = Object.keys(raw).sort((a, b) => Number(a) - Number(b));
  const quaters = Object.keys(raw[selectedYear || years[years.length - 1]])
    .sort((a, b) => Number(a) - Number(b)); 
  const subjects = Object.keys(
    raw[selectedYear || years[years.length - 1]][
      selectedQuater || quaters[quaters.length - 1]
    ]
  );

  const yearOptions: Option[] = years.map((year) => {
    return { name: year, key: year };
  });
  const quaterOptions: Option[] = quaters.map((quater) => {
    return { name: `${quater}분기`, key: quater };
  });
  const subjectOptions: Option[] = subjects.map((subject) => {
    return { name: SubjectDictionary.get(subject) || "", key: subject };
  });

  const optionsData: OptionsData = {
    yearOptions: yearOptions,
    quaterOptions: quaterOptions,
    subjectOptions: subjectOptions,
  };

  return optionsData;
};

const LoginInput = ({ onPasswordClicked }: LoginInputProp) => {
  const [name, setName] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [password, setPassword] = useState("");
  const [loginInfo, setLoginInfo] = useState("");

  const [isLoginStarted, setIsLoginStarted] = useState(false);
  const [isLoginFailed, setIsLoginFailed] = useState(false);

  const [options, setOption] = useState<OptionsData>(defalutOptions);
  const [rawOptions, setRawOption] = useState<any>();
  const [selectedOptions, setSelectedOptions] = useState({
    year: "",
    quater: "",
    subject: "",
  });
  const { year, quater, subject } = selectedOptions;

  const navigate = useNavigate();

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    switch (name) {
      case "name":
        setName(value);
        break;
      case "phonenumber":
        setPhonenumber(value);
        break;
      case "password":
        setPassword(value);
        break;
    }
  };

  const onSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    let selectedKey = e.target.value;
    let selectedType = e.target.name;
    let optionsData;

    switch (selectedType) {
      case "year":
        optionsData = processOptions(rawOptions, selectedKey, null);
        setOption(optionsData);
        setSelectedOptions({
          year: selectedKey,
          quater: optionsData.quaterOptions[0].key,
          subject: optionsData.subjectOptions[0].key,
        });
        break;
      case "quater":
        optionsData = processOptions(rawOptions, year, selectedKey);
        setOption(optionsData);
        setSelectedOptions({
          ...selectedOptions,
          quater: selectedKey,
          subject: optionsData.subjectOptions[0].key,
        });
        break;
      case "subject":
        setSelectedOptions({
          ...selectedOptions,
          subject: selectedKey,
        });
        break;
    }
  };

  const onLoginClicked = (e: React.MouseEvent<HTMLButtonElement>) => {
    let processedPhonenumber = phonenumber.replace(/-/g, "");
    processedPhonenumber = processedPhonenumber.slice(
      -8,
      processedPhonenumber.length
    );
    setLoginInfo(name + processedPhonenumber + password);
    setIsLoginStarted(true);
  };

  // Initial option fetching
  useEffect(() => {
    const fetchOptions = async () => {
      try {
        var response = await getTestData();
        setRawOption(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchOptions();
  }, []);

  // Initial option setting
  useEffect(() => {
    if (rawOptions != null) {
      setOption(processOptions(rawOptions, null, null));

      let years = Object.keys(rawOptions).sort((a, b) => Number(a) - Number(b));
      let quaters = Object.keys(rawOptions[years[years.length - 1]]).sort((a, b) => Number(a) - Number(b));
      let subjects = Object.keys(
        rawOptions[years[years.length - 1]][quaters[quaters.length - 1]]
      );

      setSelectedOptions({
        year: years[years.length - 1],
        quater: quaters[quaters.length - 1],
        subject: subjects[subjects.length - 1],
      });
    }
  }, [rawOptions]);

  // Login process
  useEffect(() => {
    if (isLoginStarted) {
      const selectedTest = {
        name: `${year}년 ${quater}분기 ${SubjectDictionary.get(subject)}`,
        id: rawOptions[year][quater][subject],
      };

      const loginProcess = async () => {
        const subj = subject === "english" ? "" : subject + "/";
        const response = await getReport(loginInfo, selectedTest.id, subj);

        console.log(response);

        const report = response.data;

        console.log(report);
        // console.log(report.score.test_type);

        if (isReportVaild(report)) {
          console.log("Login Succeed");
          let processedPhonenumber = phonenumber.replace(/-/g, "");
          processedPhonenumber = processedPhonenumber.slice(
            -8,
            processedPhonenumber.length
          );
          if (+selectedTest.id >= 14 && subject === "english") {
            const loginInfo: NewLoginInfo = {
              test: {
                name: selectedTest.name,
                id: selectedTest.id,
                subject: subject,
                type: report.score.test_type
              },
              studentName: name,
              studentGrade: report.score.grade,
              studentLevel: report.score.lv,
              phonenumber: processedPhonenumber,
              // pwd: password
            };
            console.log(loginInfo);

            setIsLoginStarted(false);

            let testType = "";
            if (+report.score.test_type >= 3) testType = "1";
            else testType = "2";

            navigate("/v2/" + subject + testType, {
              state: { report: report, info: loginInfo },
            });
          } else if (+selectedTest.id >= 14 && subject === "math") {
            const loginInfo: NewLoginInfo = {
              test: {
                name: selectedTest.name,
                id: selectedTest.id,
                subject: subject,
                type: report.score.test_type
              },
              studentName: name,
              studentGrade: report.score.grade,
              studentLevel: report.score.lv,
              phonenumber: processedPhonenumber,
              // pwd: password
            };
            console.log(loginInfo);

            setIsLoginStarted(false);

            navigate("/v2/" + subject, {
              state: { report: report, info: loginInfo },
            });
          }
          else {
            const loginInfo: LoginInfo = {
              test: {
                name: selectedTest.name,
                id: selectedTest.id,
                subject: subject,
              },
              studentName: name,
              phonenumber: processedPhonenumber,
              // pwd: password
            };

            setIsLoginStarted(false);
            console.log(loginInfo);
            navigate("/" + subject, {
              state: { report: report, info: loginInfo },
            });
          }
        } else {
          console.log("Login Failed");
          setIsLoginStarted(false);
          setIsLoginFailed(true);
        }
      };
      loginProcess();
    }
  }, [
    isLoginStarted,
    loginInfo,
    navigate,
    name,
    phonenumber,
    quater,
    rawOptions,
    subject,
    year,
  ]);

  return (
    <div className="LoginInput-container">
      <span id="Welcome-text">환영합니다.</span>
      <span id="Login-text">로그인</span>
      <Textfield
        type="text"
        name="name"
        placeholder="이름을 입력하세요...(알파벳 제외)"
        onChange={onInputChange}
      />
      <Phonefield
        name="phonenumber"
        placeholder="전화번호를 입력하세요..."
        onChange={onInputChange}
      />
      <Textfield
        type="password"
        name="password"
        placeholder="비밀번호를 입력하세요..."
        onChange={onInputChange}
      />
      <div className="Select-container">
        <Select
          name="year"
          value={year}
          options={options.yearOptions}
          onChange={onSelectChange}
        />
        <Select
          name="quater"
          value={quater}
          options={options.quaterOptions}
          onChange={onSelectChange}
        />
        <Select
          name="subject"
          value={subject}
          options={options.subjectOptions}
          onChange={onSelectChange}
        />
      </div>
      <div className="Login-footer">
        <p className="Teacher-login">
          <Link to="/teacher">강사로 로그인</Link>
        </p>
        <p className="Forgot-password" onClick={() => onPasswordClicked()}>
          비밀번호를 잊으셨나요?
        </p>
      </div>

      <Button
        name="login"
        text={isLoginStarted ? "로딩중" : "로그인"}
        onClicked={onLoginClicked}
        hasProblem={isLoginFailed}
      />
    </div>
  );
};

const LoginPage = () => {
  ReactGA.pageview("/");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const onClickForgotPassword = () => {
    setModalIsOpen(true);
  };

  return (
    <div className="Login-page">
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={modalStyle}
      >
        <div className="Forgot-Password-Dialog">
          <p className="Forgot-Password-Dialog-title">비밀번호를 잊으셨나요?</p>
          <p className="Forgot-Password-Dialog-content">
            비밀번호를 초기화하기 위해서 학원에 문의해주세요.
          </p>
          <Button
            name="close"
            text="닫기"
            onClicked={() => setModalIsOpen(false)}
            hasProblem={false}
          ></Button>
        </div>
      </Modal>
      <div className="Login-container-image">
        <img className="Login-image" src={logo} alt="logo" />
      </div>
      <div className="Login-container-input">
        <LoginInput onPasswordClicked={onClickForgotPassword} />
      </div>
    </div>
  );
};

export default LoginPage;
