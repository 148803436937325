import { LoginInfo, NewLoginInfo } from "../dto/LoginInfo";
import { changeHash } from "../repository/AuthRepository";
import { sha256 } from "js-sha256";

const isReportVaild = (grade: any) => {
  if (grade == null) return false;
  return grade.hasOwnProperty("score");
};

const changePassword = async (
  loginInfo: LoginInfo,
  newPassword: string,
  oldPassword: string
) => {
  const newHash = loginInfo.studentName + loginInfo.phonenumber + newPassword;
  const oldHash = loginInfo.studentName + loginInfo.phonenumber + oldPassword;

  const raw = await changeHash(
    loginInfo.test.subject,
    sha256(oldHash),
    sha256(newHash),
    loginInfo.test.id
  );

  const result = raw.data;

  return result === "updated";
};

export { isReportVaild, changePassword };
