import React from "react";
import {ScoreSummary, NewScoreSummary, NationalPercentage} from "../../../../data/dto/ReportDTO";
import {v4 as uuid} from "uuid";
import {GetClassName, GetTestTypeName, GetLevelName } from "../../../../data/usecase/ReportUsecase";
import './SummaryTable.scss'
import { useState } from "react";

interface SummaryTableProp {
    data: ScoreSummary
    subject: string
}

const SummaryTable = ({data, subject}: SummaryTableProp) => {
    const national_percentile_total = Number(data.summary.national_percentile_total)
    const isGradeHighEnough = national_percentile_total <= 9

    return (
        <table className="SummaryTable">
            <tbody>
            <tr className={`SummaryTable-header SummaryTable-row${isGradeHighEnough ? '-6' : ''}`}>
                <td>총 점수</td>
                <td>총 {GetClassName(data.level, subject)} 내 백분위(%)</td>
                <td>동일 레벨 내 백분위(%)</td>
                <td>총 {GetClassName(data.level, subject)} 내 등급</td>
                <td>전국 상위(%)</td>
                
            </tr>
            <tr className={`SummaryTable-row${isGradeHighEnough ? '-6' : ''}`} key={uuid()}>
                <td>{data.summary.score_total}</td>
                <td>{data.summary.cumulative_percentile_total + "%"}</td>
                <td>{data.summary.level_percentile_total + "%"}</td>
                <td>{data.summary.rating_total + ''}</td>
                <td>{data.summary.national_percentile_total + "%"}</td>
                
            </tr>
            </tbody>
        </table>
    )
}


interface NewSummaryTableProp {
    data: NewScoreSummary
    subject: string,
    national: NationalPercentage,
    grade: any,
    grammar: number | null,
    test_no: number
}

const NewSummaryTable = ({data, subject, national, grade, grammar, test_no}: NewSummaryTableProp) => {
    const [hoveredIndex, setHoveredIndex] = useState(0);

    let school = "중";
    if (grade > 3) school = "초";
    
    console.log(data);
    console.log(grammar);
        // console.log(subject === "english" ? "" : "Display-None");
    
    return (
        <div>
            <table className="New-SummaryTable">
                <tbody>
                        <tr key={uuid()} className={subject === "math" ? 'Math-Summary-Table' : ''}>
                            <td onMouseEnter={() => setHoveredIndex(0)}
                            onMouseLeave={() => setHoveredIndex(0)}>
                                <div>점수</div>
                                <div>{data.summary.score} / {data.summary.total_score}</div>
                            </td>
                            <td onMouseEnter={() => setHoveredIndex(1)}
                            onMouseLeave={() => setHoveredIndex(0)}>
                                <div>전체<br/>석차</div>
                                {subject === "math" && test_no >= 21 && +data.level === 3 && (
                                    <div>{data.summary.level_rank} / {data.summary.level_rank_total}</div>
                                )} { !(subject === "math" && test_no >= 21 && +data.level === 3) &&
                                    <div>{data.summary.total_rank} / {data.summary.total_rank_total}</div>

                                }

                            </td>
                            <td onMouseEnter={() => setHoveredIndex(2)}
                            onMouseLeave={() => setHoveredIndex(0)}>
                                {subject === "math" && test_no >= 21 && (
                                    <>
                                        <div>{GetLevelName(data.level, "math")}<br/>석차</div>
                                        <div>{data.summary.level_rank} / {data.summary.level_rank_total}</div>
                                    </>
                                    
                                )} {!(subject === "math" && test_no >= 21) && (
                                    <>
                                        <div>{school}{grade}<br/>석차</div>
                                        <div>{data.summary.grade_rank} / {data.summary.grade_rank_total}</div>
                                    </>
                                )}
                                
                                
                            </td>
                            
                            <td>
                                <div>{school}{grade}<br/>전국 백분위</div>
                                <div>{national.national_nu}%</div>
                            </td>
                            
                        </tr>
                    </tbody>
            </table>
            <table className="New-SummaryTable-Desc">
                <tbody>
                    <tr key={uuid()} className={subject === "math" ? 'Math-SummaryTable-Desc' : ''}>
                        <td></td>
                        <td className={hoveredIndex === 1 ? 'Hovered-Desc' : 'Unhovered-Desc'}>동일 시험 응시자</td>
                        <td className={hoveredIndex === 2 ? 'Hovered-Desc' : 'Unhovered-Desc'}>동일 시험 응시자</td>
                    </tr>
                </tbody>
            </table>
            <div className="info-container">
                {grammar ? (
                    <div className="Score-div">
                        수능: {+data.summary.score - grammar}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 문법: {parseInt(grammar.toString())}
                    </div>
                ) : (
                    <div></div>
                )}

                <div className={subject === "english" && data.testType >= 3 ? "National-percentage" : "Display-None"}>· 고{GetTestTypeName(data.testType, "english", grade, data.level).grade} 기준 전국 백분위: {national.highschool_nu}%</div>
            </div>
        </div>
    )
}

export { SummaryTable, NewSummaryTable };