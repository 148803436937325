import React from "react";
import {ScoreRow, ScoreSummary} from "../../../../data/dto/ReportDTO";
import {v4 as uuid} from "uuid";
import './ScoreTable.scss'
import {GetClassName} from "../../../../data/usecase/ReportUsecase";

interface ScoreTableProp {
    rows: ScoreRow[]
    total: ScoreSummary | null
    level: number
    subject: string
}

const ScoreTable = ({rows, total, level, subject}: ScoreTableProp) => {
    return (
        <table className="ScoreTable">
            <tbody>
            <tr className="ScoreTable-header">
                <td>과목명</td>
                <td>점수</td>
                <td>총 {GetClassName(level, subject)} 내 백분위(%)</td>
                <td>레벨 내 백분위(%)</td>
                <td>총 {GetClassName(level, subject)} 내 등급</td>
            </tr>
            {rows.map(
                ({row}: ScoreRow) => (
                    <tr className="ScoreTable-row" key={uuid()}>
                        <td>{row.subject_name}</td>
                        <td>{row.score}</td>
                        <td>{row.cumulative_percentile + "%"}</td>
                        <td>{row.level_percentile + "%"}</td>
                        <td>{row.rating}</td>
                    </tr>
                )
            )}
            {total != null &&
                <tr className="ScoreTable-total">
                    <td id="Total-title">총점</td>
                    <td>{total.summary.score_total}</td>
                    <td>{total.summary.cumulative_percentile_total + "%"}</td>
                    <td>{total.summary.level_percentile_total + "%"}</td>
                    <td>{total.summary.rating_total}</td>
                </tr>
            }
            </tbody>
        </table>
    )
}

export default ScoreTable;