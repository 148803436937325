import React, { useState, useEffect } from 'react';
import {v4 as uuid} from "uuid";


import {
    BarChart,
    Bar,
    XAxis,
    Tooltip,
    Legend,
    LabelList,
    ResponsiveContainer,
    YAxis,
    Cell, 
    Rectangle
} from "recharts";

const AverageGraphData = (data: any) => {
    const dataWrapper = data.data
    return (
        <ResponsiveContainer width="99%" height="99%">
            <BarChart data={dataWrapper.data}>
                <XAxis dataKey="name" fontFamily={"Noto Sans KR"}/>
                <YAxis type="number" domain={[0, 'dataMax+50']} hide={true}/>
                <Tooltip/>
                <Legend
                    verticalAlign="top"
                    height={70}
                    iconType="square"
                    iconSize={10}
                />
                <Bar dataKey="myScore" name="내 성적" fill="#FB4545">
                    <LabelList dataKey="myScore" position="top"/>
                </Bar>
                <Bar
                    dataKey="sameLevelAverage"
                    name={`${dataWrapper.level} 평균`}
                    fill="#7D7D7D"
                >
                    <LabelList dataKey="sameLevelAverage" position="top"/>
                </Bar>
                <Bar
                    dataKey="comparisonAverage"
                    name={`${dataWrapper.comparisonLevel} 평균`}
                    fill="#393939"
                >
                    <LabelList dataKey="comparisonAverage" position="top"/>
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
}




interface ChartDataItem {
    name: string;
    uv: number;
    color: string;
}


// const chartData : ChartDataItem[] = [
//     {name: "내 점수", uv: 99, color: 'rgba(255, 5, 5, 0.65)'},
//     {name: "동융형응시자평균", uv: 80, color: 'rgba(195, 0, 0, 0.15)'},
//     {name: "학년 평균", uv: 75, color: 'rgba(195, 0, 0, 0.15)',},
//     {name: "레벨 전체 평균", uv: 89, color: 'rgba(195, 0, 0, 0.15)'},
//     {name: "레벨 학년 평균", uv: 88, color: 'rgba(195, 0, 0, 0.15)'},
// ]

const CustomBar: React.FC<any> = (props) => {
    return (
        <>
      <defs>
        <filter id="shadow" x="-20%" y="-20%" width="140%" height="140%">
          <feGaussianBlur in="SourceAlpha" stdDeviation="3 8" />
          <feOffset dx="0" dy="4" result="offsetblur" />
          <feComponentTransfer>
            <feFuncA type="table" tableValues="0 0.6" />
          </feComponentTransfer>
          <feMerge>
            <feMergeNode />
            <feMergeNode in="SourceGraphic" />
          </feMerge>

        </filter>
      </defs>
      <Rectangle {...props} filter="url(#shadow)" />
    </>
    );
}

// const renderCustomLabel: React.FC<any> = (props) => {
//     const { x, y, value, index, width } = props;
//     const entry = chartData[index];
//     const name = entry.name;
//     let fontSize;
  
//     // name 값에 따른 글자 크기 설정
//     if (name === '내 점수') {
//       fontSize = '24px';
//     } else {
//       fontSize = '22px';
//     }

//     return (
//         <text x={x + width + 10} y={y + 16} fill="black" fontSize={fontSize}>
//             {value}
//         </text>
//     );
//   };

// const NewAverageGraphData = (data: any) => {
//     const dataWrapper = data.data;
//     // console.log(dataWrapper);
//     const [updatedChartData, setUpdatedChartData] = useState<ChartDataItem[]>([]);

//     useEffect(() => {
//         // if (data) {

//             const newChartData = [
//                 { ...chartData[0], uv: dataWrapper.myscore },
//                 { ...chartData[1], uv: dataWrapper.sheetAvg },
//                 { ...chartData[2], uv: dataWrapper.gradeAvg },
//                 { ...chartData[3], uv: dataWrapper.lvAvg },
//                 { ...chartData[4], uv: dataWrapper.lvGradeAvg },
//             ];

//             // if (dataWrapper.testType < 3) {
//             //     newChartData.push(...chartData.slice(5));
//             // }

//             setUpdatedChartData(newChartData);
//         // }
//     }, data);

//     // console.log(data);
//     // console.log(dataWrapper);

//     return (
//         <div>
//             <ResponsiveContainer width="95%" height={400}>
//                 <BarChart layout="vertical" data={updatedChartData} barGap={40} margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
//                 <XAxis type="number" hide={true} domain={[0, +dataWrapper.totalScore]}/>
//                 <YAxis type="category" hide={true}/>
//                 <Bar dataKey="uv" barSize={20} shape={<CustomBar/>}>
//                     {chartData.map((entry) => (
//                     <Cell fill={entry.color} key={uuid()}/>
//                     ))}
//                     <LabelList dataKey="uv" position="right" offset={10} content={renderCustomLabel} />
//                 </Bar>
//                 </BarChart>
//             </ResponsiveContainer>
//         </div>
//     );
// }

const MyScoreGraphData = (data: any) => {
    const dataWrapper = data.data;
    
    const chartData = [{name: "내 점수", uv: dataWrapper.myscore, color: 'rgba(255, 5, 5, 0.65)'}];

    return (
        <div>
            <ResponsiveContainer width="95%" height={60}>
                <BarChart layout="vertical" data={chartData} barGap={40} margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
                <XAxis type="number" hide={true} domain={[0, +dataWrapper.totalScore]}/>
                <YAxis type="category" hide={true}/>
                <Bar dataKey="uv" barSize={20} shape={<CustomBar/>}>
                    {chartData.map((entry) => (
                    <Cell fill={entry.color} key={uuid()}/>
                    ))}
                    <LabelList dataKey="uv" position="right" offset={10} style={{ fill: '#000', fontSize: '22px' }}/>
                </Bar>
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
}

const SheetGraphData = (data: any) => {
    const dataWrapper = data.data;
    
    const chartData = [{name: "동유형응시자평균", uv: dataWrapper.sheetAvg, color: 'rgba(195, 0, 0, 0.15)'}];

    return (
        <div>
            <ResponsiveContainer width="95%" height={60}>
                <BarChart layout="vertical" data={chartData} barGap={40} margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
                <XAxis type="number" hide={true} domain={[0, +dataWrapper.totalScore]}/>
                <YAxis type="category" hide={true}/>
                <Bar dataKey="uv" barSize={20} shape={<CustomBar/>}>
                    {chartData.map((entry) => (
                    <Cell fill={entry.color} key={uuid()}/>
                    ))}
                    <LabelList dataKey="uv" position="right" offset={10} style={{ fill: '#000', fontSize: '20px'}}/>
                </Bar>
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
}

const GradeGraphData = (data: any) => {
    const dataWrapper = data.data;
    
    const chartData = [{name: "학년 평균", uv: dataWrapper.gradeAvg, color: 'rgba(195, 0, 0, 0.15)'}];

    return (
        <div>
            <ResponsiveContainer width="95%" height={60}>
                <BarChart layout="vertical" data={chartData} barGap={40} margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
                <XAxis type="number" hide={true} domain={[0, +dataWrapper.totalScore]}/>
                <YAxis type="category" hide={true}/>
                <Bar dataKey="uv" barSize={20} shape={<CustomBar/>}>
                    {chartData.map((entry) => (
                    <Cell fill={entry.color} key={uuid()}/>
                    ))}
                    <LabelList dataKey="uv" position="right" offset={10} style={{ fill: '#000' , fontSize: '20px'}}/>
                </Bar>
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
}

const LevelGraphData = (data: any) => {
    const dataWrapper = data.data;
    
    const chartData = [{name: "레벨 전체 평균", uv: dataWrapper.lvAvg, color: 'rgba(195, 0, 0, 0.15)'}];

    return (
        <div>
            <ResponsiveContainer width="95%" height={60}>
                <BarChart layout="vertical" data={chartData} barGap={40} margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
                <XAxis type="number" hide={true} domain={[0, +dataWrapper.totalScore]}/>
                <YAxis type="category" hide={true}/>
                <Bar dataKey="uv" barSize={20} shape={<CustomBar/>}>
                    {chartData.map((entry) => (
                    <Cell fill={entry.color} key={uuid()}/>
                    ))}
                    <LabelList dataKey="uv" position="right" offset={10} style={{ fill: '#000', fontSize: '20px' }}/>
                </Bar>
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
}

const LevelGradeGraphData = (data: any) => {
    const dataWrapper = data.data;
    
    const chartData = [{name: "레벨 학년 평균", uv: dataWrapper.lvGradeAvg, color: 'rgba(195, 0, 0, 0.15)'}];

    return (
        <div>
            <ResponsiveContainer width="95%" height={60}>
                <BarChart layout="vertical" data={chartData} barGap={40} margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
                <XAxis type="number" hide={true} domain={[0, +dataWrapper.totalScore]}/>
                <YAxis type="category" hide={true}/>
                <Bar dataKey="uv" barSize={20} shape={<CustomBar/>}>
                    {chartData.map((entry) => (
                    <Cell fill={entry.color} key={uuid()}/>
                    ))}
                    <LabelList dataKey="uv" position="right" offset={10} style={{ fill: '#000', fontSize: '20px' }}/>
                </Bar>
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
}



  

export { AverageGraphData, MyScoreGraphData, SheetGraphData, GradeGraphData, LevelGraphData, LevelGradeGraphData };