import React, { useState, useEffect } from "react";
import "./ReportHeader.scss";
import logo from "../../../resource/cnc_logo.png";
import transparentLogo from "../../../resource/cnc_logo.jpg";
import Modal from "react-modal";
import Button from "../../../component/Button/Button";
import { Textfield } from "../../../component/Inputfield/Inputfield";
import { LoginInfo, NewLoginInfo } from "../../../../data/dto/LoginInfo";
import { changePassword } from "../../../../data/usecase/AuthUsecase";
import { useNavigate } from "react-router-dom";
import { GetLevelName, GetTestTypeName } from "../../../../data/usecase/ReportUsecase";

interface ReportHeaderProp {
  loginInfo: LoginInfo;
}

const modalStyle: Modal.Styles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 10,
    backdropFilter: "blur(10px)",
  },
  content: {
    display: "flex",
    position: "static",
    justifyContent: "center",
    background: "#F4F8FE",
    overflow: "auto",
    width: "fit-content",
    height: "fit-content",
    WebkitOverflowScrolling: "touch",
    borderRadius: "14px",
    border: "none",
    outline: "none",
    zIndex: 10,
  },
};

const ReportHeader = ({ loginInfo }: ReportHeaderProp) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isChangePasswordClicked, setIsChangePasswordClicked] = useState(false);
  const [isLoginFailed, setIsLoginFailed] = useState(false);

  const navigate = useNavigate();

  const onOldPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOldPassword(e.target.value);
  };

  const onNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.target.value);
  };

  const onClicked = () => {
    setIsChangePasswordClicked(true);
  };

  const onLogoClicked = () => {
    loginInfo.phonenumber ? navigate("/") : navigate(-1);
  };

  useEffect(() => {
    if (isChangePasswordClicked) {
      const perform = async () => {
        const res = await changePassword(loginInfo, newPassword, oldPassword);
        if (res) {
          setModalIsOpen(false);
        } else {
          setIsLoginFailed(true);
        }
      };
      perform();
      setIsChangePasswordClicked(false);
    }
  }, [isChangePasswordClicked, loginInfo, newPassword, oldPassword]);

  return (
    <div className="ReportHeader-container">
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={modalStyle}
      >
        <div className="Forgot-Password-Dialog">
          <p className="Forgot-Password-Dialog-title">비밀번호 변경하기</p>
          <p className="Forgot-Password-Dialog-content">
            비밀번호를 변경하기 위해서 아래 정보를 다시 입력해주세요
          </p>
          <Textfield
            type="password"
            name="oldpassword"
            onChange={onOldPasswordChange}
            placeholder="기존 비밀번호를 입력해주세요..."
          />
          <Textfield
            type="password"
            name="oldpassword"
            onChange={onNewPasswordChange}
            placeholder="변경할 비밀번호를 입력해주세요..."
          />
          <Button
            name="close"
            text="변경하기"
            onClicked={() => onClicked()}
            hasProblem={isLoginFailed}
          ></Button>
        </div>
      </Modal>
      <div className="ReportHeader-text start">{loginInfo.test.name}</div>
      <div className="ReportHeader-logo-contanier">
        <img
          className="ReportHeader-logo"
          src={logo}
          alt="logo"
          onClick={() => onLogoClicked()}
        />
      </div>
      <div className="ReportHeader-text end">
        {loginInfo.phonenumber && (
          <p className="Change-Password" onClick={() => setModalIsOpen(true)}>
            비밀번호 변경하기
          </p>
        )}

        <p>{loginInfo.studentName}</p>
      </div>
    </div>
  );
};


interface NewReportHeaderProp {
  loginInfo: NewLoginInfo;
}


const NewReportHeader = ({ loginInfo }: NewReportHeaderProp) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isChangePasswordClicked, setIsChangePasswordClicked] = useState(false);
  const [isLoginFailed, setIsLoginFailed] = useState(false);

  const navigate = useNavigate();

  const onOldPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOldPassword(e.target.value);
  };

  const onNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.target.value);
  };

  const onClicked = () => {
    setIsChangePasswordClicked(true);
  };

  const onLogoClicked = () => {
    loginInfo.phonenumber ? navigate("/") : navigate(-1);
  };

  useEffect(() => {
    if (isChangePasswordClicked) {
      const perform = async () => {
        const res = await changePassword(loginInfo, newPassword, oldPassword);
        if (res) {
          setModalIsOpen(false);
        } else {
          setIsLoginFailed(true);
        }
      };
      perform();
      setIsChangePasswordClicked(false);
    }
  }, [isChangePasswordClicked, loginInfo, newPassword, oldPassword]);

  console.log(loginInfo);
  // console.log(GetLevelName(+loginInfo.studentLevel, loginInfo.test.subject));
  // console.log(GetTestTypeName(+loginInfo.test.type, loginInfo.test.subject).name);

  return (
    <div className="New-ReportHeader-Container">
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={modalStyle}
      >
        <div className="Forgot-Password-Dialog">
          <p className="Forgot-Password-Dialog-title">비밀번호 변경하기</p>
          <p className="Forgot-Password-Dialog-content">
            비밀번호를 변경하기 위해서 아래 정보를 다시 입력해주세요
          </p>
          <Textfield
            type="password"
            name="oldpassword"
            onChange={onOldPasswordChange}
            placeholder="기존 비밀번호를 입력해주세요..."
          />
          <Textfield
            type="password"
            name="oldpassword"
            onChange={onNewPasswordChange}
            placeholder="변경할 비밀번호를 입력해주세요..."
          />
          <Button
            name="close"
            text="변경하기"
            onClicked={() => onClicked()}
            hasProblem={isLoginFailed}
          ></Button>
        </div>
      </Modal>

      <div className="New-ReportHeader-Logo-contanier">
        <img
          className="New-ReportHeader-Logo"
          src={transparentLogo}
          alt="logo"
          onClick={() => onLogoClicked()}
        />
      </div>

      <div className="Stu-info-container">
          <div>
            <div className="Stu-info">{loginInfo.studentGrade}학년 {GetLevelName(+loginInfo.studentLevel, loginInfo.test.subject)} {loginInfo.studentName}</div>
            <div className="New-Change-Password-Container">
              {loginInfo.phonenumber && (
                <p className="New-Change-Password" onClick={() => setModalIsOpen(true)}>
                  비밀번호 변경하기
                </p>
              )}
            </div>
          </div>
          <div className="Test-info-container">
              <div className="Quarter-subject">{loginInfo.test.name}</div>
              <div className="Grade-type"><span className={loginInfo.test.subject === 'english' ? "Display-None" : ""}>{+loginInfo.studentGrade > 3 ? "초" : "중"}{loginInfo.studentGrade} </span>{GetTestTypeName(+loginInfo.test.type, loginInfo.test.subject, +loginInfo.studentGrade, +loginInfo.studentLevel).name}</div>
          </div>
      </div>

    </div>
  );
};

export { ReportHeader, NewReportHeader };
