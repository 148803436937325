import React from "react";
import "./TestSectorTable.scss"

interface TestSectorProps {
    test_no: number
}

const TestSectorTable = ({test_no}: TestSectorProps) => {
    // console.log(test_no);
    // const TestNum = +test_no.test_no;

    return (
        <>
            {test_no === 29 && (
                <div className="TestSectorTableContainer">
                    <table>
                        <tbody>
                            <tr><th colSpan={2}>수능형 고1</th></tr>
                            <tr>
                                <td>중2</td>
                                <td>P-B / P-A / ADVAN / Inter</td>
                            </tr>
                        </tbody>
                    </table>
                    <table>
                        <tbody>
                            <tr><th colSpan={2}>수능형 고2</th></tr>
                            <tr>
                                <td>중2</td>
                                <td>0.1% / HAR / STAN / YALE</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            )}
            {test_no >= 20 && test_no !== 23 && test_no !== 29 && (
                <div className="TestSectorTableContainer">
                    <table>
                        <tbody>
                            <tr><th colSpan={2}>수능형 고1</th></tr>
                            <tr>
                                <td>중3</td>
                                <td>P-B / P-A / ADVAN / Inter</td>
                            </tr>
                        </tbody>
                    </table>
                    <table>
                        <tbody>
                            <tr><th colSpan={2}>수능형 고2</th></tr>
                            <tr>
                                <td>중3</td>
                                <td>0.1% / HAR / STAN / YALE</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            )}
            {test_no === 23 && (
                <div className="TestSectorTableContainer">
                    <table>
                        <tbody className="two-row-table">
                            <tr><th colSpan={2}>수능형 고1</th></tr>
                            <tr>
                                <td>중2</td>
                                <td rowSpan={2}>P-B / P-A / ADVAN / Inter</td>
                            </tr>
                            <tr>
                                <td>중3</td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                    <table>
                        <tbody className="one-row-table">
                            <tr><th colSpan={2}>수능형 고2</th></tr>
                            <tr>
                                <td>중2</td>
                                <td>0.1% / HAR / STAN / YALE / PRIN</td>
                            </tr>
                        </tbody>
                    </table>
                    <table>
                        <tbody className="one-row-table">
                            <tr><th colSpan={2}>수능형 고3</th></tr>
                            <tr>
                                <td>중3</td>
                                <td>0.1% / HAR / STAN / YALE</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            )}
            {test_no < 20 && (
                <div className="TestSectorTableContainer">
                    <table>
                        <tbody>
                            <tr><th colSpan={2}>고1 수능 모의평가</th></tr>
                            <tr>
                                <td>중2</td>
                                <td>Inter / Advan / 특목 P-A</td>
                            </tr>

                            <tr>
                                <td>중3</td>
                                <td>Inter / Advan</td>
                            </tr>
                        </tbody>
                    </table>
                    <table>
                        <tbody>
                            <tr><th colSpan={2}>고2 수능 모의평가</th></tr>
                            <tr>
                                <td>중2</td>
                                <td>특목 P-B / Prin / Yale</td>
                            </tr>

                            <tr>
                                <td>중3</td>
                                <td>특목 P-A / 특목 P-B / Yale</td>
                            </tr>

                        </tbody>
                    </table>
                    <table>
                        <tbody>
                            <tr><th colSpan={2}>고3 수능 모의평가</th></tr>
                            <tr>
                                <td>중2</td>
                                <td>Stan / Har / 0.1%</td>
                            </tr>
                            <tr>
                                <td>중3</td>
                                <td>Stan / Har / 0.1%</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            )}
        </>


    );
}

const IntegratedTestSectorTable = ({test_no}: TestSectorProps) => {
    return (
        <>
            {test_no >= 20 && (
                <div className="TestSectorTableContainer IntegratedContainer">
                    <table>
                        <tbody>
                            <tr><th colSpan={2}>중등 통합형</th></tr>
                            <tr>
                                <td>중1</td>
                                <td>특목 P-A / 특목 P-B</td>
                            </tr>
                            <tr>
                                <td>중2</td>
                                <td>Inter / Advan</td>
                            </tr>
                        </tbody>
                    </table>
                    <table>
                    <tbody>
                            <tr><th colSpan={2}>특목 통합형</th></tr>
                            <tr>
                                <td>중1</td>
                                <td>Stan / Har / 0.1%</td>
                            </tr>
                            <tr>
                                <td>중2</td>
                                <td>Col / Prin / Yale</td>
                            </tr>
                        </tbody>
                    </table>

                </div>

            )}
            {test_no < 20 && (
                <div className="TestSectorTableContainer IntegratedContainer">
                    <table>
                        <tbody>
                            <tr><th colSpan={2}>중등 통합형</th></tr>
                            <tr>
                                <td rowSpan={2}>중1</td>
                                <td>특목 P-A / 특목 P-B</td>
                            </tr>
                            <tr>
                                <td>Inter / Advan</td>
                            </tr>
                        </tbody>
                    </table>
                    <table>
                    <tbody>
                            <tr><th colSpan={2}>특목 통합형</th></tr>
                            <tr>
                                <td rowSpan={2}>중1</td>
                                <td>Stan / Har / 0.1%</td>
                            </tr>
                            <tr>
                                <td>Col / Prin / Yale</td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            )}
        </>

    );
}

export { TestSectorTable, IntegratedTestSectorTable};
