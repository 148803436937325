import React from 'react';
import './Button.scss'
import {buttonEvent} from '../Event/type'

interface ButtonProps {
  name: string;
  text: string;
  onClicked: buttonEvent;
  hasProblem: boolean;
}

const Button = ({name, text, onClicked, hasProblem}: ButtonProps) => {
  return (
    <div className='Button'>
      <span className='Button-text-problem'>{hasProblem ? "입력정보에 오류가 있습니다." : ""}</span>
      <button 
        name={name}
        type="button"
        onClick={onClicked}
      >
        <span className='Button-text'>{text}</span>
      </button>
    </div>
  );
}

export default Button;