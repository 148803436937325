const rating = (number: Number) => {
  if(number <= 4)
    return 1
  else if(number <= 11)
    return 2
  else if(number <= 23)
    return 3
  else if(number <= 40)
    return 4
  else if(number <= 60)
    return 5
  else if(number <= 77)
    return 6
  else if(number <= 89)
    return 7
  else if(number <= 96)
    return 8
  else
    return 9
}

export default rating