import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

import {
  GetScoreSummary,
  GetEnglishScoreRow,
  GetEnglishAverageTableData,
  GetEnglishAverageGraphData,
  GetMathScoreBySubjectRow,
  GetMathScoreByDifficultyRow,
  GetMathAverageGraphData,
  GetMathAverageTableData,
  GetClassName,
  GetNewScoreSummary,
  GetNewAverageGraphData,
  GetPercentageSummary,
  GetErrataTableData,
  GetSectorPercentageSummary,
  GetSectorAverageGraphData,
  GetNationalPercentage,
  GetUpperlevelAvg,
} from "../../../data/usecase/ReportUsecase";

import { ReportHeader, NewReportHeader } from "./component/ReportHeader";
import { SummaryTable, NewSummaryTable } from "./table/SummaryTable";
import ScoreTable from "./table/ScoreTable";
import {TitleHeader, NewTitleHeader } from "./component/TitleHeader";
import {AverageGraph, NewAverageGraph, NewMathAverageGraph } from "./graph/AverageGraph";
import EnglishAverageTable from "./table/EnglishAverageTable";
import SubjectInfo from "./component/SubjectInfo";
import { LoginInfo, NewLoginInfo } from "../../../data/dto/LoginInfo";

import { RightWrongTable, IntegratedRightWrongTable, MathRightWrongTable } from "./table/RightWrongTable";
import { TestSectorTable } from "./table/TestSectorTable";
import SectorAverageGraph from "./graph/SectorAverageGraph";
import PrevExamTable from './table/PrevExamTable';
import PrevReportTable from "./table/PrevReportTable";

import "./ReportPage.scss";
import MathAverageTable from "./table/MathAverageTable";

const EnglishReportPage = () => {
  const state = useLocation().state;
  const report = state.report;
  const info: LoginInfo = state.info;
  ReactGA.pageview(info.phonenumber ? "/english" : "/english - teacher");

  console.log(Object.keys(report));

  let grades = new Set(
    Object.keys(report)
      .filter((key) => {
        return key !== "score";
      })
      .map((key) => {
        return key.split("_")[0];
      })
  );
  console.log(grades);

  const renderTable = (): JSX.Element[] => {
    const table: JSX.Element[] = Array.from(grades).map((grade) => {
      return (
        <EnglishAverageTable
          rows={GetEnglishAverageTableData(report, grade)}
          title={(Number(grade) > 3 ? "초등학교" : "중학교") + `${grade}학년`}
        />
      );
    });
    return table;
  };

  console.log(report);

  var scores;
  const score_l = {
    lc_general_score: 80,
    lc_inference_score: 120,
    grammer_score: 100,
    rc_general_score: 80,
    rc_ksat_score: 80,
    rc_inference_score: 140,
  };
  const score_t = {
    lc_general_score: 90,
    lc_inference_score: 130,
    grammer_score: 110,
    rc_general_score: 90,
    rc_ksat_score: 90,
    rc_inference_score: 150,
  };

  if (GetClassName(report.score.lv, info.test.subject) === "특목관") {
    scores = score_l;
  } else {
    scores = score_t;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="ReportPage">
      <div className="ReportPage-container">
        <ReportHeader loginInfo={info} />
        <SummaryTable
          data={GetScoreSummary(report, info.test.subject)}
          subject={info.test.subject}
        />

        <TitleHeader title="과목 설명" comment="과목별 자세한 설명입니다" />
        <SubjectInfo scores={scores} />

        <TitleHeader
          title="성적표"
          comment="과목별 성적의 자세한 내용입니다."
        />
        <ScoreTable
          total={GetScoreSummary(report, info.test.subject)}
          rows={GetEnglishScoreRow(report)}
          subject={info.test.subject}
          level={report.score.lv}
        />

        <TitleHeader
          title="평균 그래프"
          comment="학생의 성적과 다른반의 평균 성적을 비교한 그래프입니다."
        />
        <AverageGraph data={GetEnglishAverageGraphData(report)} />

        <TitleHeader
          title="반/학년별 평균 성적 정보"
          comment="반/학년별 평균 성적 정보를 정리한 표입니다."
        />
        {renderTable()}
      </div>
    </div>
  );
};

const MathReportPage = () => {
  const state = useLocation().state;
  const report = state.report;
  const info: LoginInfo = state.info;
  ReactGA.pageview(info.phonenumber ? "/math" : "/math - teacher");

  return (
    <div className="ReportPage">
      <div className="ReportPage-container">
        <ReportHeader loginInfo={info} />
        <SummaryTable
          data={GetScoreSummary(report, info.test.subject)}
          subject={info.test.subject}
        />

        <TitleHeader
          title="과목별 성적표"
          comment="과목별 성적의 자세한 내용입니다."
        />
        <ScoreTable
          total={null}
          rows={GetMathScoreBySubjectRow(report)}
          subject={info.test.subject}
          level={report.score.lv}
        />

        <TitleHeader
          title="난이도별 성적표"
          comment="난이도별 성적의 자세한 내용입니다."
        />
        <ScoreTable
          total={GetScoreSummary(report, info.test.subject)}
          rows={GetMathScoreByDifficultyRow(report)}
          subject={info.test.subject}
          level={report.score.lv}
        />

        <TitleHeader
          title="평균 그래프"
          comment="학생의 성적과 다른반의 평균 성적을 비교한 그래프입니다."
        />
        <AverageGraph data={GetMathAverageGraphData(report)} />

        <TitleHeader
          title="반/학년별 평균 성적 정보"
          comment="반/학년별 평균 성적 정보를 정리한 표입니다."
        />
        <MathAverageTable rows={GetMathAverageTableData(report)} />
      </div>
    </div>
  );
};

const SuneongEnglishReportPage = () => {
  const state = useLocation().state;
  const report = state.report;
  const info: NewLoginInfo = state.info;
  ReactGA.pageview(info.phonenumber ? "/v2/english1" : "/v2/english1 - teacher");

  console.log(Object.keys(report));
  console.log(report);
  

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
  if (window.innerWidth < 768) {
    let metaViewport = document.createElement('meta');
    metaViewport.name = "viewport";
    metaViewport.content = "width=1024";
    document.getElementsByTagName('head')[0].appendChild(metaViewport);
  }}, []);
  
  return (
    <div className="NewReportPage">
      <div className="NewReportPage-container">
      <NewReportHeader loginInfo={info} />
        <NewSummaryTable
          data={GetNewScoreSummary(report, info.test.subject)}
          subject={info.test.subject}
          national={GetNationalPercentage(report.score)}
          grade={info.studentGrade}
          grammar={report.score.GR_score ? report.score.GR_score : 0}
          test_no={report.score.test_no}
        />

        <NewTitleHeader title="응시 단위별 성적" comment=""/>
        <NewAverageGraph chartData={GetNewAverageGraphData(report, info.test.subject)} graphData={GetPercentageSummary(report, info.test.subject)} loginInfo={info} upperlevel={GetUpperlevelAvg("")}/>

        <div className="break-before">
          <NewTitleHeader title="문항 별 결과" comment=""/>
          <RightWrongTable data={GetErrataTableData(report, info.test.subject)}/>
          
          <NewTitleHeader title="시험 구분" comment=""/>
          <TestSectorTable test_no={+info.test.id}/>
        </div>

        <PrevExamTable data={report} grade={info.studentGrade} currTestType="suneong"/>

        { report.prev_hash && (
          <div>
            <NewTitleHeader title="다른 분기 성적표 확인" comment=""/>
            <PrevReportTable data={report.prev_hash} loginInfo={info}/>
          </div>
        )}

      </div>
    </div>
  );
};

const IntegratedEnglishReportPage = () => {
  const state = useLocation().state;
  const report = state.report;
  const info: NewLoginInfo = state.info;
  console.log(info);

  ReactGA.pageview(info.phonenumber ? "/english" : "/english - teacher");

  console.log(Object.keys(report));
  console.log(report);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (window.innerWidth < 768) {
      let metaViewport = document.createElement('meta');
      metaViewport.name = "viewport";
      metaViewport.content = "width=1024";
      document.getElementsByTagName('head')[0].appendChild(metaViewport);
    }
  }, []); 

  return (
    <div className="NewReportPage">
      <div className="NewReportPage-container">
      <NewReportHeader loginInfo={info} />
        <NewSummaryTable
          data={GetNewScoreSummary(report, info.test.subject)}
          subject={info.test.subject}
          national={GetNationalPercentage(report.score)}
          grade={info.studentGrade}
          grammar={report.score.GR_score ? report.score.GR_score : 0}
          test_no={report.score.test_no}
        />

        <NewTitleHeader title="응시 단위별 성적" comment=""/>
        <NewAverageGraph 
          chartData={GetNewAverageGraphData(report, info.test.subject)} 
          graphData={GetPercentageSummary(report, info.test.subject)} 
          loginInfo={info}
          upperlevel={GetUpperlevelAvg(report.add_avg)}
        />

        <div className="break-before">
          <NewTitleHeader title="문항 별 결과" comment=""/>
          <IntegratedRightWrongTable data={GetErrataTableData(report, info.test.subject)} grade={info.studentGrade} test_no={+info.test.id}/>

          <NewTitleHeader title="영역별 성적" comment=""/>
          <SectorAverageGraph graphData={GetSectorPercentageSummary(report)} chartData={GetSectorAverageGraphData(report)}/> 
        </div>

        <PrevExamTable data={report} grade={info.studentGrade} currTestType="integrated"/>

        { report.prev_hash && (
          <div>
            <NewTitleHeader title="다른 분기 성적표 확인" comment=""/>
            <PrevReportTable data={report.prev_hash} loginInfo={info}/>
          </div>
        )}

      </div>
    </div>
    
  );
};

const NewMathReportPage = () => {
  const state = useLocation().state;
  const report = state.report;
  const info: NewLoginInfo = state.info;
  ReactGA.pageview(info.phonenumber ? "/math" : "/math - teacher");

  console.log(report);

  return (
    <div className="NewReportPage">
      <div className="NewReportPage-container">
        <NewReportHeader loginInfo={info} />
        <NewSummaryTable
          data={GetNewScoreSummary(report, info.test.subject)}
          subject={info.test.subject}
          national={GetNationalPercentage(report.score)}
          grade={info.studentGrade}
          grammar={0}
          test_no={report.score.test_no}
        />

        <NewTitleHeader title="응시 단위별 성적" comment=""/>
        <NewMathAverageGraph 
          chartData={GetNewAverageGraphData(report, info.test.subject)} 
          graphData={GetPercentageSummary(report, info.test.subject)} 
          loginInfo={info}
          upperlevel={GetUpperlevelAvg(report.add_avg)}
        />

        <NewTitleHeader title="문항 별 결과" comment=""/>
        <MathRightWrongTable data={GetErrataTableData(report, info.test.subject)}/>
      </div>
    </div>
  );
};

export { EnglishReportPage, MathReportPage, SuneongEnglishReportPage, IntegratedEnglishReportPage, NewMathReportPage };
