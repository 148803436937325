import React from "react";
// import {EnglishAverageRow} from "../../../../data/dto/ReportDTO";
import { GetPrevExamExistance, GetPrevExamData } from "../../../../data/usecase/ReportUsecase";
import { NewTitleHeader } from "../component/TitleHeader";
// import {v4 as uuid} from "uuid";
import './PrevExamTable.scss'


interface PrevExamTableProp {
    data: any,
    grade: string,
    currTestType: string
}

const PrevExamTable = ({data, grade, currTestType} : PrevExamTableProp) => {

    const showPrev = GetPrevExamExistance(data);

    
    if (showPrev) {
        const tabledata = GetPrevExamData(data.score, data.prev_exam, currTestType);

        console.log(tabledata);

        const showComparison = tabledata.testtype === currTestType ? "" : "display-none";
        
        if (tabledata.testtype === "suneong") {

            return (
                <div>
                    <NewTitleHeader title="이전 분기 성적" comment=""/>

                    <div>
                        <table className="PrevExamTable">
                            <tbody>
                                <tr>
                                    <th>영역</th>
                                    <th>백분율 및 점수</th>
                                    <th className={showComparison}>변화</th>
                                </tr>
                                <tr>
                                    <td>{grade}학년 기준</td>
                                    <td>{tabledata.prev_grade_percent}%</td>
                                    <td className={showComparison}>{Math.abs(parseFloat(tabledata.percent_change))}% {parseFloat(tabledata.percent_change) >= 0 ? "하락" : "상승"}</td>
                                </tr>
                                <tr>
                                    <td>원점수</td>
                                    <td>{tabledata.prev_total_score}점</td>
                                    <td className={showComparison}>{Math.abs(parseFloat(tabledata.score_change))}점 {parseFloat(tabledata.score_change) >= 0 ? "상승" : "하락"}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div> 
                </div>
            )
        }
        else if (tabledata.testtype === "integrated") {

            const tabledata = GetPrevExamData(data.score, data.prev_exam, currTestType);

            console.log(tabledata);

            
            return (
                <div>
                    <NewTitleHeader title="이전 분기 성적" comment=""/>

                    <div>
                        <table className="PrevExamTable IntegratedPrevExamTable">
                            <tbody>
                                <tr>
                                    <th>영역</th>
                                    <th>백분율 및 점수</th>
                                    <th className={showComparison}>변화</th>
                                </tr>
                                <tr>
                                    <td>총점</td>
                                    <td>{tabledata.prev_total_score}점</td>
                                    <td className={showComparison}>{Math.abs(parseFloat(tabledata.score_change))}점 {parseFloat(tabledata.score_change) >= 0 ? "상승" : "하락"}</td>
                                </tr>
                                <tr>
                                    <td>LC 일반</td>
                                    <td>{tabledata.prev_LC_il_score}점</td>
                                    <td className={showComparison}>{Math.abs(parseFloat(tabledata.LC_il_change))}점 {parseFloat(tabledata.LC_il_change) >= 0 ? "상승" : "하락"}</td>
                                </tr>
                                <tr>
                                    <td>LC 추론</td>
                                    <td>{tabledata.prev_LC_chu_score}점</td>
                                    <td className={showComparison}>{Math.abs(parseFloat(tabledata.LC_chu_change))}점 {parseFloat(tabledata.LC_chu_change) >= 0 ? "상승" : "하락"}</td>
                                </tr>
                                <tr>
                                    <td>GR</td>
                                    <td>{tabledata.prev_GR_score}점</td>
                                    <td className={showComparison}>{Math.abs(parseFloat(tabledata.GR_change))}점 {parseFloat(tabledata.GR_change) >= 0 ? "상승" : "하락"}</td>
                                </tr>
                                <tr>
                                    <td>RC 일반</td>
                                    <td>{tabledata.prev_RC_il_score}점</td>
                                    <td className={showComparison}>{Math.abs(parseFloat(tabledata.RC_il_change))}점 {parseFloat(tabledata.RC_il_change) >= 0 ? "상승" : "하락"}</td>
                                </tr>
                                <tr>
                                    <td>RC 수능</td>
                                    <td>{tabledata.prev_RC_su_score}점</td>
                                    <td className={showComparison}>{Math.abs(parseFloat(tabledata.RC_su_change))}점 {parseFloat(tabledata.RC_su_change) >= 0 ? "상승" : "하락"}</td>
                                </tr>
                                <tr>
                                    <td>RC 추론</td>
                                    <td>{tabledata.prev_RC_chu_score}점</td>
                                    <td className={showComparison}>{Math.abs(parseFloat(tabledata.RC_chu_change))}점 {parseFloat(tabledata.RC_chu_change) >= 0 ? "상승" : "하락"}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
    
                </div>
            )
        }
    }
    
    return null;

}


export default PrevExamTable;