import React from 'react';
import ReactGA from 'react-ga'
import { useRouteError } from "react-router-dom";

function ErrorPage() {
  ReactGA.pageview('/error');
  const error:any = useRouteError();
  console.error(error);

  return (
    <div id="error-page">
      <h1>오류가 발생했습니다.</h1>
      <p>죄송합니다, 문제가 해결되지 않는다면 문의주세요.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
}

export default ErrorPage;
