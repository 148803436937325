import { LCilAverageGraphData, LCchuAverageGraphData, 
    GRAverageGraphData, 
    RCilAverageGraphData, RCsuAverageGraphData, RCchuAverageGraphData} 
    from "./SectorAverageGraphData";

import "./SectorAverageGraph.scss"

interface SectorAverageProp {
    chartData: any, // avg
    graphData: any; // percentile
  }

const SectorAverageGraph = ({graphData, chartData}: SectorAverageProp) => {
    // console.log(graphData);
    return (
        <div>
            <table className="SectorAverageGraph">
                <tbody>
                    <tr>
                        <th>백분위</th>
                        <td>
                            <div className="Label-Row"><p>-</p><p>{graphData.LC_il_by_lv}<span className="percentage-symbol">%</span></p><p>{graphData.LC_il_by_grade}<span className="percentage-symbol">%</span></p></div>
                        </td>
                        <td>
                            <div className="Label-Row"><p>-</p><p>{graphData.LC_chu_by_lv}<span className="percentage-symbol">%</span></p><p>{graphData.LC_il_by_grade}<span className="percentage-symbol">%</span></p></div>
                        </td>
                        <td>
                            <div className="Label-Row"><p>-</p><p>{graphData.GR_by_lv}<span className="percentage-symbol">%</span></p><p>{graphData.GR_by_grade}<span className="percentage-symbol">%</span></p></div>
                        </td>
                        <td>
                            <div className="Label-Row"><p>-</p><p>{graphData.RC_il_by_lv}<span className="percentage-symbol">%</span></p><p>{graphData.RC_il_by_grade}<span className="percentage-symbol">%</span></p></div>
                        </td>
                        <td>
                            <div className="Label-Row"><p>-</p><p>{graphData.RC_su_by_lv}<span className="percentage-symbol">%</span></p><p>{graphData.RC_su_by_grade}<span className="percentage-symbol">%</span></p></div>
                        </td>
                        <td>
                            <div className="Label-Row"><p>-</p><p>{graphData.RC_chu_by_lv}<span className="percentage-symbol">%</span></p><p>{graphData.RC_chu_by_grade}<span className="percentage-symbol">%</span></p></div>
                        </td>
                    </tr>
                    <tr>
                        <th>범위</th>
                        <td className="Chart-Row"><LCilAverageGraphData data={chartData}/></td>
                        {/* <td className="Chart-Row-test"><div><LCilAverageGraphData1 data={chartData}></LCilAverageGraphData1></div><div><LCilAverageGraphData2 data={chartData}></LCilAverageGraphData2></div><div><LCilAverageGraphData3 data={chartData}></LCilAverageGraphData3></div></td> */}
                        <td className="Chart-Row"><LCchuAverageGraphData data={chartData}/></td>
                        <td className="Chart-Row"><GRAverageGraphData data={chartData}/></td>
                        <td className="Chart-Row"><RCilAverageGraphData data={chartData}/></td>
                        <td className="Chart-Row"><RCsuAverageGraphData data={chartData}/></td>
                        <td className="Chart-Row"><RCchuAverageGraphData data={chartData}/></td>
                    </tr>
                    <tr>
                        <th>범위</th>
                        <td>
                            <div className="Label-Row Vertical-text"><p>내 점수</p><p>레벨평균</p><p>학년평균</p></div>
                        </td>
                        <td>
                            <div className="Label-Row Vertical-text"><p>내 점수</p><p>레벨평균</p><p>학년평균</p></div>
                        </td>
                        <td>
                            <div className="Label-Row Vertical-text"><p>내 점수</p><p>레벨평균</p><p>학년평균</p></div>
                        </td>
                        <td>
                            <div className="Label-Row Vertical-text"><p>내 점수</p><p>레벨평균</p><p>학년평균</p></div>
                        </td>
                        <td>
                            <div className="Label-Row Vertical-text"><p>내 점수</p><p>레벨평균</p><p>학년평균</p></div>
                        </td>
                        <td>
                            <div className="Label-Row Vertical-text"><p>내 점수</p><p>레벨평균</p><p>학년평균</p></div>
                        </td>
                    </tr>
                    <tr>
                        <th>영역</th>
                        <td>LC 일반</td>
                        <td>LC 추론</td>
                        <td>GR</td>
                        <td>RC 일반</td>
                        <td>RC 수능</td>
                        <td>RC 추론</td>
                    </tr>
                </tbody>
            </table>
            <div className="Sector-details">· LC: 듣기 영역 // GR: 문법 영역 // RC: 읽기 영역</div>
        </div>
    );
}

export default SectorAverageGraph;