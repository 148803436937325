import axios from "axios";

const loginTeacher = async (
  teacher_code: string,
  password: string,
  testId: string,
  building_no: string
) => {
  let ver = "";
  if (+testId >= 14) ver = "v2/";
  const baseUrl = "https://api.cncscore.com/cnc/" + ver + "teachers/jwt.php";
  return await axios.post(baseUrl, {
    teacher_code: teacher_code,
    password: password,
    test_no: testId,
    building_no: building_no,
  });
};

const getTeacherStudents = async (
  jwt: string,
  teacher_code: string,
  testId: string,
  building_no: string
) => {
  let ver = "";
  if (+testId >= 14) ver = "v2/";
  const baseUrl = "https://api.cncscore.com/cnc/" + ver + `teachers/get_students.php?teacher_code=${teacher_code}&test_no=${testId}&building_no=${building_no}`;
  return await axios.get(baseUrl, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
};

const getStudentMath = async (jwt: string, testId: string, stu_no: string) => {
  let ver = "";
  if (+testId >= 14) ver = "v2/";
  const baseUrl = `https://api.cncscore.com/cnc/` + ver + `teachers/math_student_data.php?test_no=${testId}&stu_no=${stu_no}`;
  return await axios.get(baseUrl, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
};

const getStudentEnglish = async (
  jwt: string,
  testId: string,
  stu_no: string
) => {
  let ver = "";
  if (+testId >= 14) ver = "v2/";
  const baseUrl = 'https://api.cncscore.com/cnc/' + ver + `teachers/student_data.php?test_no=${testId}&stu_no=${stu_no}`;
  return await axios.get(baseUrl, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
};

export { loginTeacher, getTeacherStudents, getStudentMath, getStudentEnglish };
