import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./TeacherStudentsPage.scss";
import ReportHeader from "./components/ReportHeader";
import AverageTable from "./components/AverageTable";
import { StuInfo } from "../../../data/dto/TeacherInfo";
import ReactGA from "react-ga";

function TeacherStudentsPage() {
  ReactGA.pageview("/teacher");
  const navigate = useNavigate();
  const { data, info } = useLocation().state;

  const jwt = localStorage.getItem("jwt");

  const renderTable = (): JSX.Element => {
    let rows: StuInfo[] = [];

    data.students.map((stu: StuInfo) => {
      const stu_no = Object.keys(stu)[0];
      const stu_data: any = Object.values(stu)[0];
      const row: StuInfo = {
        stu_no,
        stu_name: stu_data.stu_name,
        class_no: stu_data.class_no,
      };
      rows.push(row);
    });

    return (
      <AverageTable
        rows={rows}
        test_no={data.JWT.data.test_no}
        test_name={info.test.name}
      />
    );
  };

  useEffect(() => {
    if (!jwt) {
      navigate("/teacher");
    }
  }, [jwt]);

  return (
    <div className="TeacherStudentsPage">
      <div className="TeacherStudentsWrap">
        <ReportHeader info={info} />

        {renderTable()}
      </div>
    </div>
  );
}

export default TeacherStudentsPage;
