import axios from "axios";

const getTestData = async () => {
  const baseUrl = "/cnc/test_id/";
  //const dummy = {
  //  data: {
  //      "2022": {
  //          "3": {
  //              "english": 1
  //          },
  //          "4": {
  //              "english": 4
  //          }
  //      }
  //  }
  //}
  //return dummy
  return await axios.get(baseUrl);
};

const changeHash = async (
  subject: string,
  oldHash: string,
  newHash: string,
  test_id: string
) => {
  const subj = subject === "english" ? "" : `${subject}/`;
  let version = "";
  if (+test_id >= 14) {version = "v2/"};
  const baseUrl = `/cnc/${version}${subj}password/?hash=${oldHash}&new_hash=${newHash}&test_no=${test_id}`;
  // const baseUrl = `/cnc/v2/${subj}password/?hash=${oldHash}&new_hash=${newHash}&test_no=15`;
  return await axios.get(baseUrl);
};

export { getTestData, changeHash };
