const round = (str: string) => {
    return (Math.round(Number(str) * 1e2) / 1e2).toString()
}

const round100 = (str: string) => {
    return (Math.round(Number(str) * 100 * 1e2) / 1e2).toString()
}

const setMinimum = (str: string) => {
    if(Number(str) <= 0) return '0.01'
    return str
}

export {round, round100, setMinimum}