import React from "react";
import {MathAverageRow} from "../../../../data/dto/ReportDTO";
import {v4 as uuid} from "uuid";
import './AverageTable.scss'

interface MathAverageTableProp {
    rows: MathAverageRow[]
}

const MathAverageTable = ({rows}: MathAverageTableProp) => {
    return (
        <div>
            <table className="AverageTable">
                <tbody>
                <tr className="AverageTable-header">
                    <td>반\영역</td>
                    <td>기본유형</td>
                    <td>응용유형</td>
                    <td>추론유형</td>
                    <td>심화유형</td>
                </tr>
                {rows.map(
                    ({row}: MathAverageRow) => (
                        <tr className="AverageTable-row" key={uuid()}>
                            <td>{row.class_name}</td>
                            <td>{row.diff2}</td>
                            <td>{row.diff3}</td>
                            <td>{row.diff4}</td>
                            <td>{row.diff5}</td>
                        </tr>
                    )
                )}
                </tbody>
            </table>
        </div>
    )
}

export default MathAverageTable;