import React from "react";
import LoginPage from "./auth/LoginPage";
import { EnglishReportPage, MathReportPage, SuneongEnglishReportPage, IntegratedEnglishReportPage, NewMathReportPage } from "./report/ReportPage";
import ErrorPage from "./error/ErrorPage";
import Modal from "react-modal";
import { Helmet } from "react-helmet";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.scss";
import LoginPageTeacher from "./authTeacher/LoginPage";
import TeacherStudentsPage from "./teacherStudents/TeacherStudentsPage";

const VERIFICATION_ID = process.env.REACT_APP_NAVER_SITE_VERIFICATION_ID;

const setVh = () => {
  document.documentElement.style.setProperty("--vh", `${window.innerHeight}px`);
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <LoginPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "english",
    element: <EnglishReportPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "math",
    element: <MathReportPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/teacher",
    element: <LoginPageTeacher />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/teacher/:subject",
    element: <TeacherStudentsPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "v2/english1",
    element: <SuneongEnglishReportPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "v2/english2",
    element: <IntegratedEnglishReportPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "v2/math",
    element: <NewMathReportPage />,
    errorElement: <ErrorPage />,
  },
]);

function App() {
  Modal.setAppElement("body");
  window.addEventListener("resize", setVh);
  setVh();

  return (
    <div className="App">
      <Helmet>
        <meta name="naver-site-verification" content={VERIFICATION_ID} />
      </Helmet>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
