import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './ui/pages/App';
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga';
import './index.scss';

const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;

if (typeof TRACKING_ID === 'string'){   
  console.log("ReactGA has initialized");
  ReactGA.initialize(TRACKING_ID);
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
