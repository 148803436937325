import React from "react";
import { v4 as uuid } from "uuid";
import "./AverageTable.scss";
import { StuInfo } from "../../../../data/dto/TeacherInfo";
import { useNavigate, useParams } from "react-router-dom";
import { LoginInfo, NewLoginInfo } from "../../../../data/dto/LoginInfo";
import {
  getStudentEnglish,
  getStudentMath,
} from "../../../../data/repository/TeacherRepository";

interface EnglishAverageTableProp {
  rows: StuInfo[];
  test_no: string;
  test_name: string;
}

const EnglishAverageTable = ({
  rows,
  test_no,
  test_name,
}: EnglishAverageTableProp) => {
  const { subject } = useParams();
  const navigate = useNavigate();
  const jwt = localStorage.getItem("jwt");

  if (rows.length < 1) return null;

  const onClickStudent = async (
    test_no: string,
    stu_no: string,
    stu_name: string
  ) => {
    if (!subject) return;
    try {
      if (!jwt) {
        throw new Error("jwt가 없습니다");
      }
      const response =
        subject === "english"
          ? await getStudentEnglish(jwt, test_no, stu_no)
          : await getStudentMath(jwt, test_no, stu_no);
      const report = response.data;

      const stuInfo: LoginInfo = {
        test: {
          id: test_no,
          name: test_name,
          subject,
        },
        studentName: stu_name,
      };

      const NewStuInfo: NewLoginInfo = {
        test: {
          id: test_no,
          name: test_name,
          subject: subject,
          type: report.score.test_type
        },
        studentName: stu_name,
        studentGrade: report.score.grade,
        studentLevel: report.score.lv,
      }
      
      let passedInfo = stuInfo;
      let ver = "";
      let testType = "";
      if (+test_no >= 14) {
        ver = "v2/";
        if (report.score.test_type >= 3 && stuInfo.test.subject === "english") {
          testType = "1"
        }
        if (report.score.test_type < 3 && stuInfo.test.subject === "english") {
          testType = "2"
        }
        passedInfo = NewStuInfo;
      }

      navigate(`/${ver}${subject}${testType}`, {
        state: { report: report, info: {...passedInfo} },
      });
    } catch (error) {
      console.log(error);
      localStorage.removeItem("jwt");
      navigate("/teacher");
    }
  };

  return (
    <div>
      <table className="AverageTable">
        <tbody>
          <tr className="AverageTable-header">
            <td>학생 번호</td>
            <td>이름</td>
            <td>반 번호</td>
          </tr>
          {rows.map((row: StuInfo) => (
            <tr
              className="AverageTable-row-cursor"
              key={uuid()}
              onClick={() => onClickStudent(test_no, row.stu_no, row.stu_name)}
            >
              <td>{row.stu_no}</td>
              <td>{row.stu_name}</td>
              <td>{row.class_no}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default EnglishAverageTable;
