import React, {useState} from "react";
import './AverageGraph.scss';
import {AverageGraphData, MyScoreGraphData, SheetGraphData, GradeGraphData, LevelGraphData, LevelGradeGraphData} from "./AverageGraphData";
import { NewLoginInfo } from "../../../../data/dto/LoginInfo";
import { GetLevelName, GetSuneongRanking } from "../../../../data/usecase/ReportUsecase";

const AverageGraph = (data: any) => {
  return (
    <div className="Graph-container">
      <AverageGraphData data={data.data}/>
    </div>
  );
}

interface NewAverageProp {
  chartData: any,
  graphData: any,
  loginInfo: NewLoginInfo,
  upperlevel: any|null
}

const NewAverageGraph = ({chartData, graphData, loginInfo, upperlevel}: NewAverageProp) => {
  // const [isHovered, setIsHovered] = useState(false);

  return (

    <div>
      <table className="NewAverageGraphTable">
          <tbody>
              <tr>
                  <th>영역</th>
                  <th>점수</th>
                  <th>백분위</th>
              </tr>
              <tr>
                  <td>내 점수</td>
                  <td><MyScoreGraphData data={chartData}></MyScoreGraphData></td>
                  <td>
                    <div className={+loginInfo.test.type >= 3 ? "Display-None" : ""}>-</div>
                    <div className={+loginInfo.test.type >= 3 ? "" : "Display-None"}>{GetSuneongRanking(+chartData.myscore)}등급</div>
                  </td>
              </tr>
              <tr>
                  <td>동유형응시자평균</td>
                  <td><SheetGraphData data={chartData}></SheetGraphData></td>
                  <td>{graphData.sheetPercentage}%</td>
              </tr>
              <tr>
                  <td>학년 평균</td>
                  <td><GradeGraphData data={chartData}></GradeGraphData></td>
                  <td>{graphData.gradePercentage}%</td>
              </tr>
              <tr>
                  <td>{GetLevelName(+loginInfo.studentLevel, loginInfo.test.subject)} 전체 평균</td>
                  <td><LevelGraphData data={chartData}></LevelGraphData></td>
                  <td>{graphData.lvPercentage}%</td>
              </tr>
              <tr>
                  <td>{GetLevelName(+loginInfo.studentLevel, loginInfo.test.subject)} {loginInfo.studentGrade}학년 평균</td>
                  <td><LevelGradeGraphData data={chartData}></LevelGradeGraphData></td>
                  <td>{graphData.lvGradePercentage}%</td>
              </tr>
            
          </tbody>
      </table>
      <div className={+loginInfo.test.type < 3 && upperlevel.lv !== null ? "Desc-Container" : "Display-None"}>
        <div>· {GetLevelName(upperlevel.lv, loginInfo.test.subject)} 평균 : {upperlevel.avg}</div>
      </div>
  </div>
  );
}

const NewMathAverageGraph = ({loginInfo, chartData, graphData}: NewAverageProp) => {
  console.log(chartData);
  console.log(graphData);
  console.log(loginInfo);

  return (
    <div>
      <table className="NewAverageGraphTable">
          <tbody>
              <tr>
                  <th>영역</th>
                  <th>점수</th>
                  <th>백분위</th>
              </tr>
              <tr>
                  <td>내 점수</td>
                  <td><MyScoreGraphData data={chartData}></MyScoreGraphData></td>
                  <td>
                    <div>-</div>
                  </td>
              </tr>
              <tr>
                  <td>동유형응시자평균<br/>{+loginInfo.test.id < 21 && (<span className="Add-desc">(0.1%, 의대, 특목)</span>)}</td>
                  {+loginInfo.test.id >= 21 && +loginInfo.studentLevel === 3 && (
                    <>
                      <td><LevelGraphData data={chartData}></LevelGraphData></td>
                      <td>{graphData.lvPercentage}%</td>
                    </>
                  )} {!(+loginInfo.test.id >= 21 && +loginInfo.studentLevel === 3) && (
                    <>
                      <td><SheetGraphData data={chartData}></SheetGraphData></td>
                      <td>{graphData.sheetPercentage}%</td>
                    </>
                  )}
              </tr>
              
              <tr>
                  <td>동일 레벨 평균</td>
                  <td><LevelGraphData data={chartData}></LevelGraphData></td>
                  <td>{graphData.lvPercentage}%</td>
              </tr>
          </tbody>
      </table>
  </div>
  );
}

export { AverageGraph, NewAverageGraph, NewMathAverageGraph };
