import React from 'react';
import { selectEvent } from '../Event/type'
import { v4 as uuid } from "uuid";
import Option from '../../../data/dto/Option';
import './Select.scss'

interface SelectProps {
  name: string;
  value: string;
  options: Option[];
  onChange: selectEvent;
}

const Select = ({name, value, options, onChange}: SelectProps) => {
  return (
  <select name={name} value={value} onChange={onChange} className="Select-component">
    {options.map((option) => (
      <option
        key={uuid()}
        value={option.key}
      >
        {option.name}
      </option>
    ))}
  </select>
  );
}

export default Select;