import axios from "axios";
import { sha256 } from "js-sha256";

/** {name + processedPhonenumber + password}, TestID, "" "math" */
async function getReport(data: string, testId: string, subject: string) {
  // console.log(testId);
  // console.log(data);
  let version = "";
  if (+testId >= 14) {version = "v2/";}
  else version = "v1/";

  let hash = sha256(data);
  if (data.startsWith("hash")) {
    hash = data.slice(4);
  }
  const baseUrl = "/cnc/" + version + subject + "?hash=" + hash + "&test_no=" + testId;

  return await axios.get(baseUrl);
}

export default getReport;